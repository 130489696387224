import React from "react";

import { Spinner } from "components";
import { Wrapper } from "./styles";

const LoadingMask = ({ solidBg = false }) => {
  return (
    <Wrapper>
      <Spinner size={24} color="#fff" $solidBg={solidBg} />
    </Wrapper>
  );
};

export default LoadingMask;
