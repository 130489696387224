import React from "react";
import PropTypes from "prop-types";

import { ClickableComponentWrapper } from "./styles";

const ClickableComponent = ({ children, ...props }) => {
  return (
    <ClickableComponentWrapper {...props}>{children}</ClickableComponentWrapper>
  );
};

ClickableComponent.propTypes = {
  children: PropTypes.any,
};

export default ClickableComponent;
