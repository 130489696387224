import styled from "styled-components";
import { Div, Text } from "components";

export const ListItem = styled.li`
  text-decoration: none;
  background-color: white;
  display: flex;
  padding-top: 5px;
  padding-bottom: 10px;
  flex-direction: column;
  outline: 1px solid #f8f9fa;
  margin-bottom: 8px;
  cursor: pointer;

  &:hover {
    cursor: pointer;
    outline: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  }
`;

export const AssetLabelContainer = styled.div`
  display: flex;
`;

export const ColorBar = styled.div`
  margin: 0;
  padding: 0;
  background-color: ${({ barColor }) => barColor};
  min-width: 4px;
  max-width: 4px;
  height: 16px;
  margin-right: 8px;
  margin-bottom: 4px;
  margin-top: 4px;
`;

export const AssetLabel = styled(Text)`
  color: #3a5267;
  vertical-align: text-top;

  .highlighted-word {
    font-weight: bolder;
    color: inherit;
    background-color: inherit;
  }

  /* @media (max-width: 920px) {
    color: #27618f;
  } */
`;

export const AssetType = styled.small`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fonts.size.md};
  color: rgba(102, 102, 102, 0.8);
  line-height: ${({ theme }) => theme.fonts.lineHeight[1]};
  font-weight: ${({ theme }) => theme.fonts.weight[0]};
`;

export const AssetInfoContainer = styled(Div)`
  display: flex;
  margin-left: 12px;

  span:first-child {
    border: none;
    margin: 0;
    padding: 0;
  }

  span {
    margin-left: 5px;
    padding-left: 5px;
    border-left: 1px solid rgba(102, 102, 102, 0.8);

    /* Não aplicar estilos nas palavras destacadas */
    span {
      border: none;
      margin: 0;
      padding: 0;
    }
  }

  /* @media (max-width: 920px) {
    flex-direction: column;

    span {
      border: none;
      margin: 0;
      padding: 0;
    }
  } */
`;
