import styled from "styled-components";
import { rgba } from "polished";

export const Wrapper = styled.header`
  background-color: white;
  display: flex;
  align-items: center;
  padding: 0px 32px;
  border-bottom: ${({ theme, bgColor, showBorder }) =>
    bgColor || !showBorder
      ? "0"
      : `1px solid ${rgba(theme.colors.gray[theme.text[0]], 0.3)}`};

  /* @media (max-width: 920px) {
    padding: 0;
    background-color: ${({ theme }) =>
    theme.colors[theme.background.quaternary.color][
      [theme.background.quaternary.intensity]
    ]};
    border-bottom: none;
  } */
`;
