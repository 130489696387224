import styled from "styled-components";
import { Div, Img } from "components";

export const ModalContainer = styled(Div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ed4337;
  width: 220px;
  height: 58px;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  /* @media (max-width: 920px) {
    padding: 0;
    width: 100%;
  } */
`;

export const Body = styled(Div)`
  font-family: ${({ theme }) => theme.fonts.primary};
  color: white;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: ${({ theme, themeType }) =>
    themeType === "dark"
      ? theme.colors[theme.textColors.secondary.color][
          theme.textColors.secondary.intensity
        ]
      : theme.colors[theme.textColors.primary.color][
          theme.textColors.primary.intensity
        ]};
`;

export const StyledImg = styled(Img)`
  margin-top: 5px;
  margin-right: 6px;
  opacity: 0.75;
`;
