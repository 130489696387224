import styled from "styled-components";
import { Div } from "components";

export const Wrapper = styled(Div)`
  min-width: 280px;
  background-color: white;
  padding: 16px 0;
  margin: 0px 16px 16px 16px;
  margin-bottom: 32px;
  -webkit-box-shadow: 1px 5px 21px 3px rgba(0, 0, 0, 0.11);
  box-shadow: 1px 5px 21px 3px rgba(0, 0, 0, 0.11);

  /* @media (max-width: 920px) {
    background-color: ${({ theme }) =>
    theme.colors[theme.background.tertiary.color][
      theme.background.tertiary.intensity
    ]};
  } */
`;

export const Title = styled.div`
  display: flex;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  padding-bottom: 12px;
  margin: 0 12px;
  margin-bottom: 16px;
  justify-content: space-between;
  font-family: ${({ theme }) => theme.fonts.primary};
  align-items: center;
  color: ${({ theme }) =>
    theme.colors[theme.textColors.primary.color][
      theme.textColors.primary.intensity
    ]};

  strong {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
  }

  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }
`;

export const AssetRowWrapper = styled.li`
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  & + & {
    margin-top: 24px;
  }
`;

export const LeftContainer = styled(Div)`
  display: flex;
  max-width: 190px;
`;

export const RightContainer = styled(Div)`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const CloseButton = styled.button`
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: ${({ theme }) =>
    theme.colors[theme.textColors.primary.color][
      theme.textColors.primary.intensity
    ]};
`;

export const AssetTitle = styled.strong`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: ${({ theme, themeType }) =>
    themeType
      ? theme.colors[theme.textColors.secondary.color][
          theme.textColors.secondary.intensity
        ]
      : theme.colors[theme.textColors.primary.color][
          theme.textColors.primary.intensity
        ]};
`;

export const AssetLabel = styled.span`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: ${({ theme, themeType }) =>
    themeType
      ? theme.colors[theme.textColors.secondary.color][
          theme.textColors.secondary.intensity
        ]
      : theme.colors[theme.textColors.primary.color][
          theme.textColors.primary.intensity
        ]};
  margin-top: 4px;
`;

export const RemoveButton = styled.button`
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  display: flex;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: ${({ theme }) =>
    theme.colors[theme.textColors.error.color][
      theme.textColors.error.intensity
    ]};
`;

export const ModalContainer = styled(Div)`
  display: flex;
  flex-direction: column;
`;

export const TopBar = styled(Div)`
  display: flex;
  justify-content: flex-end;
  padding: 12px 16px;
  border-bottom: 1px solid rgba(33, 33, 33, 0.2);
`;

export const Body = styled(Div)`
  display: flex;
  flex-direction: column;
  padding: 16px;
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme, themeType }) =>
    themeType === "dark"
      ? theme.colors[theme.textColors.secondary.color][
          theme.textColors.secondary.intensity
        ]
      : theme.colors[theme.textColors.primary.color][
          theme.textColors.primary.intensity
        ]};
`;

export const BottomBar = styled(Div)`
  display: flex;
  padding: 16px 0;
  justify-content: center;
  border-top: 1px solid rgba(33, 33, 33, 0.2);
`;

export const AssetList = styled.ul``;

export const AssetName = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) =>
    theme.colors[theme.textColors.primary.color][
      theme.textColors.primary.intensity
    ]};
  max-width: 200px;
`;

export const Value = styled.span`
  margin-right: 12px;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: 600;
  line-height: 16px;
  font-size: 14px;
  text-align: end;

  /* @media (max-width: 920px) {
    color: ${({ theme }) =>
    theme.colors[theme.textColors.primary.color][
      theme.textColors.primary.intensity
    ]};
  } */
`;
