import React, { useState, useEffect, useRef } from "react";
import Modal from "react-modal";
import { useMyContext } from "hooks";
import {
  Body,
  ModalContainer,
  CloseButton,
  BottomBar,
  LeftColumn,
  RightColumn,
  QRTitle,
  QRText,
} from "./styles";
import QRCode from "qrcode.react";
import { QR_CODE_SQUARE_SIZE } from "models/constants";

const SharedLinkModal = ({ isOpen, onClose, link }) => {
  const { themeType, t } = useMyContext();

  const { innerWidth } = window;
  const [windowSize, setWindowSize] = useState(innerWidth);

  const handleClose = () => {
    onClose();
  };

  // TODO:
  // OBS.: Por toda a aplicação foi verificado o innerWidth para tentar dar uma responsividade,
  // mas da forma com que isso foi feito, não ocorre uma releitura da informação, somente se recarregar
  // a página.
  // Essa talvez seja uma solução. Se estiver OK, inserir no início do App e criar uma entrada no
  // redux para manter esse valor.
  // Então deve-se alterar todo o app onde o innerWidth é utilizado.
  const handleResize = () => {
    setWindowSize(1000);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      style={{
        overlay: {
          backgroundColor: "rgba(51, 51, 51, 0.75)",
        },
        content:
          windowSize <= 0
            ? {
                top: "auto",
                right: 0,
                left: 0,
                padding: 0,
                bottom: 0,
              }
            : {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                border: "0",
                backgroundColor: "rgba(51, 51, 51, 0)",
                position: "static",
                inset: "0",
              },
      }}
    >
      <ModalContainer>
        <Body themeType={themeType}>
          <LeftColumn>
            <QRTitle>{t["molecules:Modals:MobileFooter:shareQRTitle"]}</QRTitle>
            <QRText>
              {t["molecules:Modals:MobileFooter:shareQRCodeMessage"]}
            </QRText>
          </LeftColumn>
          <RightColumn>
            <QRCode id="QRCode" value={link} size={QR_CODE_SQUARE_SIZE} />
          </RightColumn>
        </Body>
        <BottomBar>
          <CloseButton themeType={themeType} onClick={handleClose}>
            {t["molecules:Modals:MobileFooter:close"]}
          </CloseButton>
        </BottomBar>
      </ModalContainer>
    </Modal>
  );
};

export default SharedLinkModal;
