import styled from "styled-components";
import { ClickableComponent, Div } from "components";

export const Wrapper = styled(Div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px 0 0 0;

  /* @media (max-width: 920px) {
    padding: 0;
  } */
`;

export const Header = styled(Div)`
  width: 100%;
  flex-direction: row;
  display: flex;
  justify-content: ${({ theme }) => theme.header.align};

  /* @media (max-width: 920px) {
    flex-wrap: wrap;
    margin-left: 0 !important;
    justify-content: ${({ theme }) => theme.header.align};
    padding: 0;
  } */
`;

export const ImageWrapper = styled(Div)`
  display: flex;
  /* @media (max-width: 920px) {
    width: 100%;
    padding: 10px 0px;
    justify-content: center;
    background-color: ${({ theme }) =>
    theme.colors[theme.background.sextenary.color][
      [theme.background.sextenary.intensity]
    ]};
  } */
`;

export const ViewSelectedAssets = styled(ClickableComponent)`
  margin: 10px 15px;
  color: white;
  font-size: 14px;
  line-height: 16px;
  text-decoration: underline;
  text-align: left;
`;
