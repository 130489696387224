import styled from "styled-components";

import { Div } from "components";

export const Wrapper = styled(Div)`
  margin: 32px 26px 0;
  font-family: ${({ theme }) => theme.fonts.primary};

  /* @media (max-width: 920px) {
    margin: 0;
  } */
`;

export const HeaderContainer = styled(Div)`
  background-color: ${({ theme }) =>
    theme.colors[theme.background.tertiary.color][
      theme.background.tertiary.intensity
    ]};
  padding: 16px 24px;
  color: ${({ theme }) =>
    theme.colors[theme.textColors.secondary.color][
      theme.textColors.secondary.intensity
    ]};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fonts.size.lg};
  font-weight: ${({ theme }) => theme.fonts.weight[2]};
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    cursor: pointer;
  }
`;

export const BodyContainer = styled(Div)`
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  box-shadow: 0px 2px 22px rgba(51, 51, 51, 0.18);
  padding: 0 24px;
  background-color: #fff;
  padding-bottom: 16px;
`;
