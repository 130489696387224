import styled from "styled-components";
import { Div } from "components";
import { Grid } from "@mui/material";

export const Chart = styled(Div)`
  padding: 32px;
  display: flex;
  justify-content: start;
  margin-top: 2px;
  overflow-x: auto;

  /* @media (max-width: 920px) {
    padding: 0;
    justify-content: center;
    align-items: center;
  } */
`;

export const AssetsList = styled.ul`
  width: fit-content;
  list-style-type: none;

  /* @media (max-width: 920px) {
    width: 100%;
    padding: 5%;
    background-color: ${({ theme }) =>
    theme.colors[theme.background.tertiary.color][
      theme.background.tertiary.intensity
    ]};
  } */
`;

export const Spacer = styled.li`
  padding: 104px 0 16px 12px;
  color: transparent;
  font-family: ${({ theme }) => theme.fonts.primary};
  /* @media (max-width: 920px) {
    padding: 12px 0px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    margin-bottom: 12px;
    span {
      color: ${({ theme }) =>
    theme.colors[theme.textColors.primary.color][
      theme.textColors.primary.intensity
    ]};
    }
  } */
`;

export const AssetsItem = styled.li`
  min-height: 50px;
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) =>
    theme.colors[theme.textColors.primary.color][
      theme.textColors.primary.intensity
    ]} !important;
  padding: 14px 0 12px;
  white-space: nowrap;
  text-overflow: ellipsis;

  /* @media (max-width: 920px) {
    padding: 12px 0px;
  } */
`;

// export const Content = styled(Div)`
//   display: flex;
//   margin-bottom: 35px;
//   width: fit-content;
//   flex-direction: column;

//   @media (max-width: 920px) {
//     flex-direction: column-reverse;
//     justify-content: center;
//     margin-bottom: 0px;
//   }
// `;

export const Content = styled(Grid)`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;

    /* @media (max-width: 920px) {
      flex-direction: column-reverse;
      justify-content: center;
      margin-bottom: 0px;
    } */
  }
`;

export const FirstRow = styled(Grid)`
  && {
  }
`;

export const SecondRow = styled(Grid)`
  && {
    display: flex;
    flex-direction: row;
    width: fit-content;

    /* @media (max-width: 920px) {
      flex-direction: column-reverse;
      justify-content: center;
      margin-bottom: 0px;
      width: auto;
    } */
  }
`;

export const Legend = styled(Div)`
  padding-left: 47px;
  width: fit-content;

  /* @media (max-width: 920px) {
    display: flex;
    padding: 32px 0 35px;
    width: 100%;
  } */
`;

export const ChartContainer = styled(Grid)``;

export const Title = styled(Div)`
  font-style: normal;
  line-height: 24px;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  padding-top: 12px;
  padding-left: 26px;
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) =>
    theme.colors[theme.textColors.primary.color][
      theme.textColors.primary.intensity
    ]} !important;
  width: fit-content;

  /* @media (max-width: 920px) {
    display: none;
  } */
`;
