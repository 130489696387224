import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { useMyContext } from "hooks";
import {
  Body,
  TopBar,
  ModalContainer,
  CloseButton,
  BottomBar,
  Link,
  Copy,
  StyledImg,
} from "./styles";
import copyIcon from "../../../../assets/copyIcon.svg";

const SharedLinkModal = ({ bottom, body, isOpen, onClose, link }) => {
  const { themeType, t } = useMyContext();

  const [copied, setCopied] = useState(false);

  const { innerWidth } = window;
  const [windowSize, setWindowSize] = useState(innerWidth);

  const copyToClipboard = () => {
    link && navigator.clipboard.writeText(link);
    setCopied(true);
  };

  const handleClose = () => {
    setCopied(false);
    onClose();
  };

  // TODO:
  // OBS.: Por toda a aplicação foi verificado o innerWidth para tentar dar uma responsividade,
  // mas da forma com que isso foi feito, não ocorre uma releitura da informação, somente se recarregar
  // a página.
  // Essa talvez seja uma solução. Se estiver OK, inserir no início do App e criar uma entrada no
  // redux para manter esse valor.
  // Então deve-se alterar todo o app onde o innerWidth é utilizado.
  const handleResize = () => {
    setWindowSize(1000);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      style={{
        overlay: {
          backgroundColor: "rgba(51, 51, 51, 0.75)",
        },
        content:
          windowSize <= 0
            ? {
                top: "auto",
                right: 0,
                left: 0,
                padding: 0,
                bottom: 0,
              }
            : {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "400px",
                border: "0",
                backgroundColor: "rgba(51, 51, 51, 0)",
              },
      }}
    >
      <ModalContainer>
        <TopBar>
          <CloseButton themeType={themeType} onClick={handleClose}>
            {t["molecules:Modals:MobileFooter:close"]}
          </CloseButton>
        </TopBar>
        <Body themeType={themeType}>
          {body || t["molecules:Modals:MobileFooter:shareLinkMessage"]}
        </Body>
        {link && (
          <>
            <Link themeType={themeType} onClick={copyToClipboard}>
              {link}
            </Link>
            <Copy
              themeType={themeType}
              style={{
                display: "flex",
                alignItems: "center",
              }}
              onClick={copyToClipboard}
            >
              <StyledImg src={copyIcon} height="18px" width="18px" />
              {copied
                ? t["molecules:Modals:MobileFooter:copyed"]
                : t["molecules:Modals:MobileFooter:copy"]}
            </Copy>
          </>
        )}
        {!!bottom && <BottomBar>{bottom}</BottomBar>}
      </ModalContainer>
    </Modal>
  );
};

export default SharedLinkModal;
