import styled from "styled-components";

export const Wrapper = styled.footer`
  width: 100%;
  position: relative;
  bottom: 0;
  display: flex;
  background-color: ${({ theme }) =>
    theme.colors[theme.background.tertiary.color][
      theme.background.tertiary.intensity
    ]};
  color: ${({ theme }) =>
    theme.colors[theme.textColors.secondary.color][
      theme.textColors.secondary.intensity
    ]};
  justify-content: center;
  padding: 24px 0px;
  align-items: center;
`;
