export const API_URL = 'https://api.quantumaxis.com.br/widgets/stage/'

export const API_SUBSCRIPTION_KEY = 'eee9e4860f85432b93bd43db8514aaff'

export const HOME_TITLE = 'Super Carteira,'

export const THEME = 'super-carteira'

export const SHARE_URL = 'https://supercarteira-stage.quantumfinance.com.br/asset#';
export const REDUX_PANE = false;
export const REDUX_LOGGER = false;
export const SUPERCARTEIRA_ROOT_URL = 'https://supercarteira-stage.quantumfinance.com.br/';
