import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Wrapper = styled.span`
  color: ${({ theme, color }) =>
    theme.colors[color || theme.defaulTextColor][theme.text[0]]};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme, size }) => theme.sizes.title.size[size]};
  line-height: ${({ theme, lineHeight }) =>
    theme.sizes.title.lineHeight[lineHeight]};
  font-weight: ${({ theme, fontWeight }) =>
    theme.sizes.title.weight[fontWeight]};
`;

const Title = ({
  id,
  children,
  className,
  size,
  color,
  lineHeight,
  fontWeight,
}) => {
  return (
    <Wrapper
      id={id}
      className={className}
      size={size}
      lineHeight={lineHeight}
      fontWeight={fontWeight}
      color={color}
    >
      {children}
    </Wrapper>
  );
};

Title.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
  size: PropTypes.oneOf([0, 1, 2, 3, 4, 5]),
  lineHeight: PropTypes.oneOf([0, 1, 2, 3, 4, 5]),
  fontWeight: PropTypes.oneOf([0, 1, 2, 3, 4, 5]),
  color: PropTypes.oneOf(["white", "black", "gray"]),
};

export default Title;
