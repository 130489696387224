import React from "react";
import { Title } from "components";
import { useMyContext } from "hooks";
import { Wrapper } from "./styles";

const TitleHome = () => {
  const { t } = useMyContext();

  const innerWidth = 1000;
  return (
    <Wrapper id="title">
      <Title
        id="title-1"
        size={innerWidth > 920 ? 0 : 1}
        lineHeight={innerWidth > 920 ? 0 : 1}
        fontWeight={0}
        color={innerWidth > 920 ? "gray" : "white"}
      >
        {t["molecules:TitleHome:Title:title-1"]}
      </Title>
      <Title
        id="title-2"
        size={innerWidth > 920 ? 0 : 1}
        lineHeight={innerWidth > 920 ? 0 : 1}
        fontWeight={1}
        color={innerWidth > 920 ? "gray" : "white"}
      >
        {t["molecules:TitleHome:Title:title-2"]}
      </Title>
    </Wrapper>
  );
};

export default TitleHome;
