import defaultTheme from "./clients/defaultTheme";
import ouiTheme from "./clients/ouiTheme";
import superCarteiraTheme from "./clients/superCarteiraTheme";

const Themes = {
  default: defaultTheme,
  "oui-investimentos": ouiTheme,
  "super-carteira": superCarteiraTheme,
};

export default Themes;
