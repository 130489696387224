import { useSelector, useDispatch } from "react-redux";

export const useSelectState = () => useSelector((state) => state);

export const useSelectAssets = () => {
  const dispatch = useDispatch();
  const assets = useSelector((state) => state.assets);
  const setAssets = (assets) =>
    dispatch({ type: "ADD_ASSETS", payload: assets });

  return [assets, setAssets];
};

export const useSelectAssetsWithoutSeries = () => {
  const dispatch = useDispatch();
  const assetsWithoutSeries = useSelector((state) => state.assetsWithoutSeries);
  const setAssetsWithoutSeries = (assets) =>
    dispatch({ type: "SET_ASSETS_WITHOUT_SERIES", payload: assets });
  return [assetsWithoutSeries, setAssetsWithoutSeries];
};

export const useSelectPeriod = () => {
  const dispatch = useDispatch();
  const selectedPeriod = useSelector((state) => state.selectedPeriod);
  const setSelectedPeriod = (selectedPeriod) =>
    dispatch({ type: "SET_PERIOD", payload: selectedPeriod });

  return [selectedPeriod, setSelectedPeriod];
};

export const useSelectInicialApplication = () => {
  const dispatch = useDispatch();
  const initialApplication = useSelector((state) => state.initialApplication);
  const setInitialApplication = (initialApplication) =>
    dispatch({ type: "SET_INITIAL_APPLICATION", payload: initialApplication });

  return [initialApplication, setInitialApplication];
};

export const useSelectTab = () => {
  const dispatch = useDispatch();
  const selectedTab = useSelector((state) => state.selectedTab);
  const setSelectedTab = (selectedTab) =>
    dispatch({ type: "SET_TAB", payload: selectedTab });

  return [selectedTab, setSelectedTab];
};

export const useSelectBenchmarksIndexes = () => {
  const dispatch = useDispatch();
  const selectedBenchmarksIndexes = useSelector(
    (state) => state.selectedBenchmarksIndexes
  );
  const setSelectedBenchmarksIndexes = (selectedBenchmarksIndexes) =>
    dispatch({ type: "SET_BENCHMARKS", payload: selectedBenchmarksIndexes });

  return [selectedBenchmarksIndexes, setSelectedBenchmarksIndexes];
};

export const useBenchmarkAssets = () => {
  const dispatch = useDispatch();
  const benchmarkAssets = useSelector((state) => state.benchmarkAssets);
  const setBenchmarkAssets = (benchmarkAssets) =>
    dispatch({ type: "SET_BENCHMARKS_ASSETS", payload: benchmarkAssets });

  return [benchmarkAssets, setBenchmarkAssets];
};

export const usePeriodRange = () => {
  const dispatch = useDispatch();
  const periodRange = useSelector((state) => state.periodRange);
  const setPeriodRange = ({ initialDate, finalDate }) =>
    dispatch({ type: "SET_PERIOD_RANGE", payload: { initialDate, finalDate } });

  return [periodRange, setPeriodRange];
};

export const useSelectSharedLink = () => {
  const dispatch = useDispatch();

  const sharedLink = useSelector((state) => state.sharedLink);
  const createSharedLink = (sharedLink) =>
    dispatch({
      type: "SET_SHARED_LINK",
      payload: sharedLink,
    });

  return [sharedLink, createSharedLink];
};

export const useCorrelationData = () => {
  const dispatch = useDispatch();
  const correlationData = useSelector((state) => state.correlationData);
  const setCorrelationData = (correlationData) =>
    dispatch({ type: "SET_CORRELATION_DATA", payload: correlationData });

  return [correlationData, setCorrelationData];
};

export const useFetchingAssetsSeries = () => {
  const dispatch = useDispatch();

  const isFetchingAssetsSeries = useSelector(
    (state) => state.isFetchingAssetsSeries
  );
  const setFetchingAssetsSeries = (isFetchingAssetsSeries) =>
    dispatch({
      type: "FETCHING_ASSETS_SERIES",
      payload: isFetchingAssetsSeries,
    });

  return [isFetchingAssetsSeries, setFetchingAssetsSeries];
};

export const useBaseDate = () => {
  const dispatch = useDispatch();

  const baseDate = useSelector((state) => state.baseDate);

  const setBaseDate = (baseDate) =>
    dispatch({
      type: "SET_BASE_DATE",
      payload: baseDate,
    });

  return [baseDate, setBaseDate];
};

export const useLastUpdateDate = () => {
  const dispatch = useDispatch();

  const lastUpdateDate = useSelector((state) => state.lastUpdateDate);

  const setLastUpdateDate = (lastUpdateDate) =>
    dispatch({
      type: "SET_LAST_UPDATE_DATE",
      payload: lastUpdateDate,
    });

  return [lastUpdateDate, setLastUpdateDate];
};

export const useUserHasInteractedWithPeriod = () => {
  const dispatch = useDispatch();

  const userHasInteractedWithPeriod = useSelector(
    (state) => state.userHasInteractedWithPeriod
  );

  const setUserHasInteractedWithPeriod = (userHasInteractedWithPeriod) =>
    dispatch({
      type: "SET_USER_HAS_INTERACTED_WITH_PERIOD",
      payload: userHasInteractedWithPeriod,
    });

  return [userHasInteractedWithPeriod, setUserHasInteractedWithPeriod];
};
