import React from "react";

import { Spinner } from "components";
import { Message, Wrapper } from "./styles";
import { useMyContext } from "hooks";

const LinkLoadingModal = () => {
  const { t } = useMyContext();

  return (
    <Wrapper>
      <Message>{t["share:loadingLink"]}</Message>
      <Spinner size={24} color="#777" />
    </Wrapper>
  );
};

export default LinkLoadingModal;
