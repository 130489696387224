import styled from "styled-components";
import {
  Grid,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Typography,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

export const Span = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Wrapper = styled(Grid)`
  && {
    display: flex;
    padding: 33px 26px 0;
    background-color: ${({ theme }) => theme.background.secondary.color};
    overflow-x: auto;
  }
`;

export const STable = styled(Table)`
  && {
    background-color: ${({ theme }) => theme.background.secondary.color};
  }
`;

export const STableBody = styled(TableBody)`
  && {
  }
`;

export const STableRow = styled(TableRow)`
  && {
    align-items: center;
    min-height: 32px;
  }
`;

export const STableHead = styled(TableHead)`
  && {
  }
`;

export const LeftContent = styled(Grid)`
  && {
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const AssetLabel = styled(Typography)`
  && {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.gray[0]};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const RightContent = styled(Grid)`
  && {
    display: flex;
    padding-left: 8px;
    align-items: center;

    &:hover {
      cursor: pointer;
    }
  }
`;

export const SDeleteIcon = styled(DeleteOutlineIcon)`
  && {
    font-size: 18px;
    margin-right: 8px;
    color: ${({ theme }) => theme.colors.blue[2]};
  }
`;

export const STypography = styled(Typography)`
  && {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    height: 20px;
  }
`;

export const SCell = styled(TableCell)`
  && {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.gray[0]};
    border-bottom: 0px;
    padding: 8px;
    height: 100%;

    &:first-child {
      display: flex;
      padding-left: 0px;
      text-align: left;
      align-items: center;
      justify-content: space-between;
    }
  }
`;

export const SHeaderCell = styled(TableCell)`
  && {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-size: 14px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.gray[0]};
    border-bottom: 0px;
    padding: 8px;
    height: 100%;

    &:first-child {
      display: flex;
      padding-left: 24px;
      text-align: left;
      align-items: center;
      justify-content: space-between;
    }
  }
`;

export const SBar = styled.div`
  height: 4px;
  width: 16px;
  min-width: 16px;
  content: "";
  background-color: ${({ barColor }) => barColor || "black"};
  margin-right: 8px;
`;
