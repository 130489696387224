import { parse, stringify } from "query-string";
import {
  URL_FIELDS_SIZE,
  AMOUNT_MIN,
  AMOUNT_MAX,
  VALID_TABS,
  VALID_BENCHMARKS,
  VALID_PERIODS,
  BENCHMARKS_ASSETS_IDS,
} from "../models/constants";
import { SHARE_URL } from "environment";

const queryStringOptions = {
  arrayFormat: "separator",
  arrayFormatSeparator: ",",
  encode: true,
};

const assetsSeparator = "|";

const assetsToString = (assets) => {
  let assetPair = assets.map((asset) => {
    if (BENCHMARKS_ASSETS_IDS.includes(asset.identifier) === false) {
      return asset.assetType + assetsSeparator + asset.identifier;
    } else {
      return "";
    }
  });

  assetPair = assetPair.filter((asset) => asset !== "");
  return assetPair.length === 0 ? [assetsSeparator] : assetPair;
};

export const generateSharedLink = (
  initialApplication,
  selectedTabState,
  selectedBenchmarksIndexes,
  selectedPeriod,
  assets
) => {
  try {
    const reportState = {
      initialAmount: initialApplication.toFixed(2).split("."),
      tab: VALID_TABS[selectedTabState],
      benchmarks: selectedBenchmarksIndexes.map(
        (benchmarksIdx) => VALID_BENCHMARKS[benchmarksIdx]
      ),
      period: selectedPeriod,
      assets: assetsToString(assets),
      // assets: ["ACAO|700", "ACAO|114"],
    };

    return stringify(reportState, queryStringOptions);
  } catch {
    console.log("Erro gerando o link");
    return null;
  }
};

export const urlParser = (reportConfig) => {
  try {
    let config = parse(reportConfig, queryStringOptions);

    const sizeValidator = Object.keys(config).length === URL_FIELDS_SIZE;

    const amountValidator =
      config.initialAmount.length === 2 &&
      config.initialAmount[0] > AMOUNT_MIN &&
      config.initialAmount[1] < AMOUNT_MAX;
    if (amountValidator) {
      config.initialAmount = Number(
        String(config.initialAmount).replace(/,/g, ".")
      );
    }

    const tabValidator = VALID_TABS.includes(config.tab);
    if (tabValidator) {
      config.tab = VALID_TABS.indexOf(config.tab);
    }

    const benchmarksValidator =
      typeof config.benchmarks === "string"
        ? [VALID_BENCHMARKS.indexOf(config.benchmarks)].length === 1
        : [...config.benchmarks].filter((x) => VALID_BENCHMARKS.includes(x))
            .length === config.benchmarks.length;

    if (benchmarksValidator) {
      if (typeof config.benchmarks === "string") {
        config.benchmarks = [VALID_BENCHMARKS.indexOf(config.benchmarks)];
      } else {
        config.benchmarks = [...config.benchmarks].map((idx) =>
          VALID_BENCHMARKS.indexOf(idx)
        );
      }
    }

    const periodValidator = VALID_PERIODS.includes(config.period);

    const assetsValidator = config.assets.length >= 1;

    if (assetsValidator) {
      if (typeof config.assets === "string") {
        const asset = config.assets.split(assetsSeparator);
        const assetType = asset[0];
        const identifier = asset[1];

        config.assets =
          config.assets === assetsSeparator
            ? []
            : [{ assetType: assetType, identifier: identifier }];
      } else {
        const decodedAssets = config.assets.map((assets) => {
          assets = assets.split(assetsSeparator);
          const assetType = assets[0];
          const identifier = assets[1];
          return {
            assetType,
            identifier,
          };
        });

        config.assets = decodedAssets;
      }
    }

    const configValidator = [
      sizeValidator,
      amountValidator,
      tabValidator,
      benchmarksValidator,
      periodValidator,
      assetsValidator,
    ];

    const isConfigValid = configValidator.every(
      (validator) => validator === true
    );

    // For debugging:
    // console.log("Recebido: ", JSON.stringify(config, 1, 1));
    // console.log(configValidator);
    // console.log("RDEBUG: ", isConfigValid);

    return isConfigValid ? config : null;
  } catch {
    return null;
  }
};

export const getCurrentUrlBase = () => {
  if (SHARE_URL === "default") {
    const prot = window.location.protocol;
    const host = window.location.host;
    const path = "graphic/";
    const URL = prot + "//" + host + "/" + path;
    return URL;
  } else {
    return SHARE_URL;
  }
};
