import React from "react";

import { Icon } from "components";
import {
  Wrapper,
  Label,
  ColorFlag,
  ButtonContainer,
  StyledButton,
} from "./styles";

const SelectedAssetItem = ({ asset, removeAsset }) => {
  return (
    <Wrapper>
      <ColorFlag color={asset.color} />
      <Label>{asset.label}</Label>
      <ButtonContainer>
        <StyledButton
          onClick={() => removeAsset(asset.identifier, asset.assetType)}
        >
          <Icon iconname="delete" color="white" size="16" />
        </StyledButton>
      </ButtonContainer>
    </Wrapper>
  );
};

export default SelectedAssetItem;
