import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const getColor = ({ theme, status }) =>
  ({
    error:
      theme.colors[theme.input.bordercolors.error.color][
        theme.input.bordercolors.error.intensity
      ],
    success:
      theme.colors[theme.input.bordercolors.success.color][
        theme.input.bordercolors.success.intensity
      ],
  }[status]);

const InputWrapper = styled.input`
  -webkit-user-select: text;
  user-select: text;
  color: ${(props) =>
    getColor(props) ||
    props.theme.colors[props.theme.input.textcolor.color][
      props.theme.input.textcolor.intensity
    ]};
  font-weight: bold;
  font-size: 14px;
  font-family: ${(props) => props.theme.fonts.primary};
  line-height: 112%;

  ::placeholder {
    color: ${({ theme }) =>
      theme.colors[theme.input.placeholder.primary.color][
        theme.input.placeholder.intensity
      ]};
    font-size: ${({ theme }) => theme.input.size[0]};
  }

  width: ${({ fullWidth }) => (fullWidth ? "100%" : "344px")};

  background: ${({ theme }) =>
    theme.colors[theme.input.background.color][
      theme.input.background.intensity
    ]};
  border: 1px solid
    ${(props) =>
      getColor(props) ||
      props.theme.colors[props.theme.input.bordercolors.primary.color][
        props.theme.input.bordercolors.primary.intensity
      ]};

  box-sizing: border-box;

  :hover {
    border: 1px solid
      ${({ theme }) =>
        theme.colors[theme.input.bordercolors.hover.color][
          theme.input.bordercolors.hover.intensity
        ]};
  }
  :focus {
    padding-left: ${({ theme }) => `calc(${theme.sizes.spacing[3]} - 1px)`};
    outline: none;
    border: 1px solid
      ${({ theme }) =>
        theme.colors[theme.input.bordercolors.focus.color][
          theme.input.bordercolors.focus.intensity
        ]};
  }

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
`;

const Input = forwardRef(
  (
    {
      id,
      className,
      type,
      status,
      fullWidth,
      required,
      maxLength,
      placeholder,
      onChange,
      onFocus,
      onBlur,
      onKeyPress,
      disabled,
    },
    ref
  ) => {
    return (
      <InputWrapper
        className={className}
        id={id}
        type={type}
        ref={ref}
        status={status}
        fullWidth={fullWidth}
        required={required}
        placeholder={placeholder}
        maxLength={maxLength}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyPress={onKeyPress}
        disabled={disabled}
        autoComplete="off"
      />
    );
  }
);

Input.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  status: PropTypes.oneOf(["error", "success"]),
  required: PropTypes.bool,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

export default Input;
