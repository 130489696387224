import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useWindowSize } from "react-use";

import { Footer } from "components";
import { useMyContext } from "hooks";
import {
  Content,
  FirstHeader,
  SecondHeader,
  ThirdHeader,
  FourthHeader,
  PaperWrapper,
  Wrapper,
} from "./styles";

const GraphicTemplate = ({
  header,
  secondHeader,
  thirdHeader,
  fourthHeader,
  footer,
  children,
}) => {
  const { theme, themeType, setThemeType } = useMyContext();
  const { width } = useWindowSize();

  useEffect(() => {
    if (width <= 0 && themeType !== "dark") {
      setThemeType("dark");
    } else if (width > 0 && themeType !== "light") {
      setThemeType("light");
    }
  }, [width, themeType]);

  return (
    <Wrapper id="graphic-teamplate">
      <FirstHeader showBorder={theme.header.first.showBorder}>
        {header}
      </FirstHeader>

      <SecondHeader bgColor="white" showBorder={false}>
        {secondHeader}
      </SecondHeader>

      {width <= 0 ? (
        <FourthHeader bgColor="white" showBorder={false}>
          {fourthHeader}
        </FourthHeader>
      ) : null}

      <ThirdHeader bgColor="white" showBorder={false}>
        {thirdHeader}
      </ThirdHeader>

      {width > 0 ? (
        <FourthHeader bgColor="white" showBorder={false}>
          {fourthHeader}
        </FourthHeader>
      ) : null}

      <Content>
        {width > 0 ? <PaperWrapper>{children}</PaperWrapper> : <>{children}</>}
      </Content>

      {footer && <Footer>{footer}</Footer>}
    </Wrapper>
  );
};

GraphicTemplate.propTypes = {
  header: PropTypes.node.isRequired,
  secondHeader: PropTypes.node,
};

export default GraphicTemplate;
