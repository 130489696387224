import styled from "styled-components";
import { Div, Textfield } from "components";

export const Wrapper = styled(Div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${({ theme }) => theme.searchAssets.width};
  padding: ${({ theme }) => theme.searchAssets.padding};

  /* @media (max-width: 920px) {
    width: 100%;
  } */
`;

export const StyledTextField = styled(Textfield)`
  border: 1px solid
    ${({ theme, isFocused }) =>
      isFocused ? theme.colors.blue[theme.borders[1]] : "white"};
  padding: 12px 0;
  border-radius: 4px;

  /* @media (max-width: 920px) {
    margin-top: ${({ theme }) => theme.searchAssets.marginTop};
    border: none;
    border-radius: 4px;
    background-color: white;
    width: 100%;
  } */
`;

export const SearchListWrapper = styled(Div)`
  display: flex;
  position: relative;
  flex-direction: column;
  z-index: 1;
  width: ${({ theme }) => theme.searchAssets.listResult.width};
`;

export const SearchList = styled.ul`
  width: inherit;
  display: flex;
  position: absolute;
  flex-direction: column;
  list-style-type: none;
  border: 1px solid ${({ theme }) => theme.colors.gray[theme.borders[0]]};
  border-top: none;
  padding 0 16px;
  padding-bottom: 16px;
  background-color: white;
  max-height: 480px;
  overflow: auto;
`;

export const CategorySeparatorLine = styled.p`
  width: 100%;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  border-bottom: 1px solid ${({ barColor }) => barColor};
  color: ${({ barColor }) => barColor};
  margin-top: 6px;
  margin-bottom: 6px;
`;
