import React from "react";

import { useMyContext } from "hooks";
import SearchAssetInfoItem from "../SearchAssetInfoItem";

const SearchEuropeFundInfo = ({
  isin,
  situation,
  managementCompany,
  wordsToHighlight,
}) => {
  const { t } = useMyContext();

  return (
    <>
      <SearchAssetInfoItem
        label={t["organisms:SearchAssets:isin"]}
        value={isin}
        wordsToHighlight={wordsToHighlight}
      />
      <SearchAssetInfoItem
        label={t["organisms:SearchAssets:situation"]}
        value={situation}
      />
      <SearchAssetInfoItem
        label={t["organisms:SearchAssets:managementCompany"]}
        value={managementCompany}
      />
    </>
  );
};

export default SearchEuropeFundInfo;
