import React from "react";
import PropTypes from "prop-types";
import { Wrapper } from "./styles";

const NavItem = ({ index, isSelected, className, onClick, children }) => {
  return (
    <Wrapper
      index={index}
      isSelected={isSelected}
      className={className}
      onClick={onClick}
    >
      {children}
    </Wrapper>
  );
};

NavItem.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

export default NavItem;
