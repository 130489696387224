import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Wrapper, ContentWrapper, StyledHeader } from "./styles";
import { Footer } from "components";
import { useMyContext } from "hooks";
import { useWindowSize } from "react-use";

const MainTemplate = ({ header, children, footer }) => {
  const { themeType, setThemeType } = useMyContext();
  const { width } = useWindowSize();

  useEffect(() => {
    if (width <= 0 && themeType !== "dark") {
      setThemeType("dark");
    } else if (width > 0 && themeType !== "light") {
      setThemeType("light");
    }
  }, [width, themeType]);

  return (
    <Wrapper id="MainTemplate">
      <StyledHeader showBorder>{header}</StyledHeader>
      <ContentWrapper>{children}</ContentWrapper>
      {footer && <Footer>{footer}</Footer>}
    </Wrapper>
  );
};

MainTemplate.propTypes = {
  children: PropTypes.any.isRequired,
  header: PropTypes.node.isRequired,
  footer: PropTypes.node,
};

export default MainTemplate;
