import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const ImgWrapper = styled.img`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`;

const Img = ({
  id,
  alt,
  src,
  width,
  height,
  color,
  style,
  className,
  onClick,
}) => {
  return (
    <ImgWrapper
      id={id}
      alt={alt}
      src={src}
      width={width}
      height={height}
      color={color}
      style={style}
      className={className}
      onClick={onClick}
    />
  );
};

Img.propTypes = {
  id: PropTypes.string,
  alt: PropTypes.string,
  src: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

export default Img;
