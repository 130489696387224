import React from "react";

import { StyledFooterText, StyledImg } from "./styles";
import logoQuantum from "assets/logoQuantum.svg";

const FooterGraphicPage = () => (
  <>
    <StyledFooterText textsize="sm" style={{ marginRight: "8px" }}>
      Powered by
    </StyledFooterText>
    <a
      href="https://www.quantumfinance.com.br"
      target="_blank"
      rel="noreferrer"
    >
      <StyledImg src={logoQuantum} />
    </a>
  </>
);

export default FooterGraphicPage;
