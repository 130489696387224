import styled from "styled-components";
import { IconButton, Span as Sp } from "components";

export const StyledIconButton = styled(IconButton)`
  border: 0;
  padding: 0;
  color: ${({ theme }) =>
    theme.colors[theme.iconColor.primary.color][
      theme.iconColor.primary.intensity
    ]};
`;

export const StyledSpan = styled(Sp)`
  padding-right: 24px;
  padding-left: 8px;
`;
