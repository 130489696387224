import ES from "./ES";
import ptBR from "./ptBR";
import enUS from "./enUS";

const translations = {
  "en-US": enUS,
  "pt-BR": ptBR,
  ES,
};

export default translations;
