import React from "react";
import { useMyContext } from "hooks";
import { useSelectTab } from "state";
import { useWindowSize } from "react-use";

import { StyledTabs, StyledTab, Wrapper, NavLeft, NavItem } from "./styles";

const NavigationHeader = () => {
  const [selectedTab, setSelectedTab] = useSelectTab();
  const { t } = useMyContext();
  const { width } = useWindowSize();

  const handleTabSelection = (_event, newTabValue) => {
    setSelectedTab(newTabValue);
  };

  const handleNavItemClick = (e) => {
    const index = e.currentTarget.dataset.index;
    setSelectedTab(Number(index));
  };

  return (
    <Wrapper>
      {width > 0 ? (
        <StyledTabs
          value={selectedTab}
          onChange={handleTabSelection}
          scrollButtons={false}
          variant="fullWidth"
        >
          <StyledTab value={0} label={t["tabs:Rentability"]} />
          <StyledTab value={1} label={t["tabs:Return"]} />
          <StyledTab value={2} label={t["tabs:Underwater"]} />
          <StyledTab value={3} label={t["tabs:Correlation"]} />
        </StyledTabs>
      ) : (
        <NavLeft>
          <NavItem
            index={0}
            isSelected={selectedTab === 0}
            onClick={handleNavItemClick}
          >
            {t["tabs:Rentability"]}
          </NavItem>
          <NavItem
            index={1}
            isSelected={selectedTab === 1}
            onClick={handleNavItemClick}
          >
            {t["tabs:Return"]}
          </NavItem>
          <NavItem
            index={2}
            isSelected={selectedTab === 2}
            onClick={handleNavItemClick}
          >
            {t["tabs:Underwater"]}
          </NavItem>
          <NavItem
            index={3}
            isSelected={selectedTab === 3}
            onClick={handleNavItemClick}
          >
            {t["tabs:Correlation"]}
          </NavItem>
        </NavLeft>
      )}
    </Wrapper>
  );
};

export default NavigationHeader;
