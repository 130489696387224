import React from "react";
import PropTypes from "prop-types";
import {
  Wrapper,
  ActivesContainer,
  StyledText,
  SelectedAssetsContainer,
  TitleContainer,
  SelectedAssetsList,
  SButton,
  DeleteIcon,
} from "./styles";
import { Chip, SelectedAssetItem } from "components";
import { useMyContext } from "hooks";
import { useWindowSize } from "react-use";

const SelectedAssets = ({ assets, removeAsset, clearAssets }) => {
  const { t, theme } = useMyContext();
  const { width } = useWindowSize();

  if (assets.length === 0) return <></>;

  return (
    <Wrapper>
      {width > 0 ? (
        <ActivesContainer>
          {assets.map((asset) => (
            <Chip
              id={asset.identifier + asset.assetType}
              key={asset.identifier + asset.assetType}
              item={asset}
              deleteChip={() => removeAsset(asset.identifier, asset.assetType)}
              showFullName={theme.showAssetFullName}
            />
          ))}
          <SButton onClick={clearAssets}>
            <DeleteIcon />
            {t["pages:HomePage:ClearActives"]}
          </SButton>
        </ActivesContainer>
      ) : (
        <SelectedAssetsContainer>
          <TitleContainer>
            <StyledText id="selectedActives:title" textsize="md" bold>
              {t["organisms:SelectedActives:title"]}
            </StyledText>
          </TitleContainer>
          <SelectedAssetsList>
            {assets.map((asset) => (
              <SelectedAssetItem
                key={asset.identifier + asset.assetType}
                asset={asset}
                removeAsset={removeAsset}
              />
            ))}
          </SelectedAssetsList>
        </SelectedAssetsContainer>
      )}
    </Wrapper>
  );
};

SelectedAssets.propTypes = {
  assets: PropTypes.array,
  removeActive: PropTypes.func,
  clearActives: PropTypes.func,
};

export default SelectedAssets;
