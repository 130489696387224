import translations from "i18n/LanguageProvider/locales";
const Utils = () => {
  const t = translations["pt-BR"];
  const months = [
    t["month:0"],
    t["month:1"],
    t["month:2"],
    t["month:3"],
    t["month:4"],
    t["month:5"],
    t["month:6"],
    t["month:7"],
    t["month:8"],
    t["month:9"],
    t["month:10"],
    t["month:11"],
  ];
  const shortMonths = [
    t["shortMonth:0"],
    t["shortMonth:1"],
    t["shortMonth:2"],
    t["shortMonth:3"],
    t["shortMonth:4"],
    t["shortMonth:5"],
    t["shortMonth:6"],
    t["shortMonth:7"],
    t["shortMonth:8"],
    t["shortMonth:9"],
    t["shortMonth:10"],
    t["shortMonth:11"],
  ];

  const date2DigitsFormatter = new Intl.DateTimeFormat("pt-BR", {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
  });

  const dateFullYearFormatter = new Intl.DateTimeFormat("pt-BR", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  const numberFormatter = new Intl.NumberFormat("pt-BR", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const dateUTC = (date, utc) => {
    const dt = new Date(date);
    return new Date(dt.setHours(dt.getHours() + utc));
  };

  const monthNameForDate = (date) => {
    const newDate = dateUTC(date, 3);
    const monthIndex = newDate.getMonth();
    return months[monthIndex];
  };

  const shortMonthNameForDate = (date) => {
    const newDate = new Date(date);
    const monthIndex = newDate.getMonth();
    return shortMonths[monthIndex];
  };

  const yearForDate = (date) => {
    const newDate = new Date(date);
    const year = newDate.getFullYear().toString();
    return year.substr(2, 2);
  };

  const fullYearForDate = (date) => {
    const newDate = new Date(date);
    const year = newDate.getFullYear().toString();
    return year;
  };

  const formatDate = (date) => {
    const newDate = dateUTC(date, 3);
    return `${newDate.getUTCDate()} ${monthNameForDate(
      date
    )} ${newDate.getFullYear()} `;
  };

  const formatDate2Digits = (date) => {
    return date ? date2DigitsFormatter.format(dateUTC(date, 3)) : "-";
  };

  const formatDateFullYear = (date) => {
    return date ? dateFullYearFormatter.format(dateUTC(date, 3)) : "-";
  };

  const formatPeriod = (finalDate, initialDate) => {
    let initialD = initialDate && new Date(initialDate);
    let finalD = finalDate && new Date(finalDate);
    //TODO: refact + 3
    initialD = initialD && initialD.setHours(initialD.getHours() + 3);
    finalD = finalD.setHours(finalD.getHours() + 3);

    return initialD
      ? `${date2DigitsFormatter.format(initialD)} ${
          t["period:to"]
        }  ${date2DigitsFormatter.format(finalD)}`
      : `${date2DigitsFormatter.format(finalD)}`;
  };

  const fillZero = (number) => {
    return number < 10 ? "0" + number : number;
  };

  const formatCurrency = (value) =>
    new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);

  const formatPercent = (value) => `${formatNumber(value)}%`;

  const formatNumber = (value) => numberFormatter.format(value);

  const removeDiacritics = (string) => {
    return string.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  return {
    monthNameForDate,
    shortMonthNameForDate,
    yearForDate,
    fullYearForDate,
    formatDate,
    formatDate2Digits,
    fillZero,
    formatPeriod,
    formatCurrency,
    formatPercent,
    formatNumber,
    removeDiacritics,
    date2DigitsFormatter,
    formatDateFullYear,
  };
};

export default Utils;
