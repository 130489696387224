import styled from "styled-components";
import { Tab, Tabs, Grid } from "@mui/material";
import Item from "../../molecules/NavItem";

export const Wrapper = styled(Grid)`
  && {
    width: 100%;
    margin-top: 10px;
    border-top: 1px solid #f1f3f5;

    /* @media (max-width: 920px) {
      border-top: none;
      margin-top: 15px;
      margin-bottom: 5px;
    } */
  }
`;

export const StyledTabs = styled(Tabs)`
  && {
    width: 100%;
    margin-right: -50%;

    .MuiTabs-indicator {
      background-color: ${({ theme }) => theme.colors.gray[0]};
      height: 3px;
    }
  }
`;

export const StyledTab = styled(Tab)`
  && {
    background: ${({ theme }) => theme.colors.white[0]};
    box-sizing: border-box;
    border-bottom: 3px solid transparent;
    height: 40px;
    width: 148px;
    text-transform: capitalize;
    color: ${({ theme }) => theme.colors.gray[0]};
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-size: 14px;
    line-height: 24px;

    &.Mui-selected {
      font-weight: bold;
      color: ${({ theme }) => theme.colors.gray[0]} !important;
      border-bottom: 3px solid ${({ theme }) => theme.colors.gray[0]};
    }
  }
`;

export const NavLeft = styled.ul`
  display: flex;
  list-style-type: none;
  border: 1px solid ${({ theme }) => theme.colors.gray[theme.borders[0]]};

  /* @media (max-width: 920px) {
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 8px;
    padding-bottom: 12px;
    border: none;
    overflow-x: auto;

    /* CSS para forçar scroll a apararecer sempre no modo mobile */
    /* ::-webkit-scrollbar {
      -webkit-appearance: none;
    }
    ::-webkit-scrollbar:horizontal {
      height: 4px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 3px;
      border: 1px solid #ffffff;
    }
    ::-webkit-scrollbar-track {
      border-radius: 3px;
      background-color: #ffffff;
    } */
  } */
`;

export const NavItem = styled(Item)`
  border: ${({ hasBorder, theme }) =>
    !!hasBorder &&
    `1px solid ${
      theme.colors[theme.background.tertiary.color][
        theme.background.tertiary.intensity
      ]
    }`};
  padding: ${({ hasBorder }) => !!hasBorder && "10px 14px"};
  border-radius: ${({ hasBorder }) => !!hasBorder && "4px"};
  font-size: 16px;
  & + & {
    margin-left: ${({ hasBorder }) => (!!hasBorder ? "12px" : "0px")};
  }

  &:last-of-type {
    cursor: auto;
  }

  /* @media (max-width: 920px) {
    border: 1px solid white;
    border-radius: 2px;
    & + & {
      margin-left: 12px;
    }
  } */
`;
