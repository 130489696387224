import React, { useState } from "react";
import PropTypes from "prop-types";
import { Wrapper, ColorFlag, StyledText, StyledIconButton } from "./styles";
import colorFlag from "assets/colorFlag.svg";
import { CATEGORY_COLORS } from "../../../models/constants";

const Chip = ({ id, className, item, deleteChip, showFullName }) => {
  const [hidden, setHidden] = useState(true);

  const hadleOnMouseEnter = () => {
    setHidden(false);
  };

  const hadleOnMouseLeave = () => {
    setHidden(true);
  };

  return (
    <Wrapper
      id={id}
      className={className}
      onMouseEnter={hadleOnMouseEnter}
      onMouseLeave={hadleOnMouseLeave}
      showFullName={showFullName}
    >
      <ColorFlag
        src={colorFlag}
        color={
          item.assetType === "EUROPA" ||
          item.assetType === "FI" ||
          item.assetType === "FII" ||
          item.assetType === "FIDC"
            ? CATEGORY_COLORS["FUNDO"]
            : CATEGORY_COLORS[item.assetType]
        }
      />
      <StyledText>{item.label}</StyledText>
      <StyledIconButton
        hidden={hidden}
        type="button"
        size="md"
        iconname="close"
        bgColor="white"
        onClick={() => deleteChip(item.identifier)}
      />
    </Wrapper>
  );
};

Chip.propTypes = {
  id: PropTypes.string,
  item: PropTypes.shape({
    name: PropTypes.string,
    color: PropTypes.string,
  }),
  deleteChip: PropTypes.func,
  showFullName: PropTypes.bool,
};

export default Chip;
