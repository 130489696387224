import styled from "styled-components";
import { Span as Sp, Div, Button, Title } from "components";

export const Picker = styled(Sp)`
  background-color: ${(props) => props.value} !important;
  border: none;
  min-width: 4px;
  height: 16px;
  cursor: pointer;
  margin-right: 8px;
  box-shadow: none;

  ::-webkit-color-swatch-wrapper {
    padding: 0;
  }
  ::-webkit-color-swatch {
    border: none;
  }
  ::-webkit-color-swatch,
  ::-moz-color-swatch {
    border-color: transparent;
  }

  /* @media (max-width: 920px) {
    min-width: 8px;
  } */
`;

export const ModalContainer = styled(Div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10000;
`;

export const StyledButton = styled(Button)`
  color: ${({ theme }) =>
    theme.colors[theme.textColors.secondary.color][
      theme.textColors.secondary.intensity
    ]};
  background-color: ${({ theme, bgColor }) =>
    bgColor
      ? bgColor
      : theme.colors[theme.background.tertiary.color][
          theme.background.tertiary.intensity
        ]};
  border: none;
  border-radius: 2px;

  :hover {
    background-color: ${({ theme, bgColor }) =>
      bgColor
        ? bgColor
        : theme.colors[theme.background.tertiary.color][
            theme.background.tertiary.intensity
          ]};
  }

  & + button {
    margin-left: 10px;
  }

  /* @media (max-width: 920px) {
    color: ${({ theme }) =>
    theme.colors[theme.textColors.primary.color][
      theme.textColors.primary.intensity
    ]};
  } */
`;

export const ButtonsContainer = styled(Div)`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

export const StyledTitle = styled(Title)`
  margin-bottom: 12px;
`;
