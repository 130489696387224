import styled from "styled-components";
import {
  Grid,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Typography,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

export const Span = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Wrapper = styled(Grid)`
  && {
    padding: 5px 26px 0;
    background-color: ${({ theme }) => theme.background.secondary.color};
    overflow-x: auto;
  }
`;

export const STable = styled(Table)`
  && {
    background-color: ${({ theme }) => theme.background.secondary.color};
  }
`;

export const STableBody = styled(TableBody)`
  && {
  }
`;

export const STableRow = styled(TableRow)`
  && {
    align-items: center;
    min-height: 32px;
  }
`;

export const STableHead = styled(TableHead)`
  && {
  }
`;

export const LeftContent = styled(Grid)`
  && {
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const AssetLabel = styled(Typography)`
  && {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.gray[0]};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const RightContent = styled(Grid)`
  && {
    display: flex;
    padding-left: 8px;
    align-items: center;

    &:hover {
      cursor: pointer;
    }
  }
`;

export const SDeleteIcon = styled(DeleteOutlineIcon)`
  && {
    font-size: 18px;
    margin-right: 8px;
    color: ${({ theme }) => theme.colors.blue[2]};
  }
`;

export const STypography = styled(Typography)`
  && {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-size: 14px;
    height: 20px;
  }
`;

export const AlignRight = styled(Grid)`
  && {
    display: inline-block;
    text-align: right;
    direction: rtl;
  }
`;

export const SHeaderCell = styled(TableCell)`
  && {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-size: 14px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.gray[0]};
    border-bottom: 0px;
    padding: 8px;
    height: 100%;

    &:first-child {
      display: flex;
      padding-left: 24px;
      text-align: left;
      align-items: center;
      justify-content: space-between;
    }
  }
`;

export const SCell = styled(TableCell)`
  && {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.gray[0]};
    border-bottom: 0px;
    padding: 8px;
    height: 100%;

    &:first-child {
      display: flex;
      padding-left: 0px;
      text-align: left;
      align-items: center;
      justify-content: space-between;
    }
  }
`;

export const SBar = styled.div`
  height: 4px;
  width: 16px;
  min-width: 16px;
  content: "";
  background-color: ${({ barColor }) => barColor || "black"};
  margin-right: 8px;
`;

// import { Table, Td, Th, ColorPicker } from 'components'

// export const StyledTable = styled(Table)`
//   width: 100%;
//   border-collapse: collapse;
//   overflow: auto;
// `;

// export const StyledTd = styled(Td)`
//   border: 1px solid rgba(51, 51, 51, 0.2);
//   padding: 16px 0;
//   text-align: center;
//   font-weight: ${({theme}) => theme.fonts.weight[0]};
//   font-size: ${({theme}) => theme.fonts.size.md};
//   line-height: ${({theme}) => theme.fonts.lineHeight[3]};
//   min-width: 352px;

//   &:first-child{
//     border-left: none;
//     font-weight: ${({theme}) => theme.fonts.weight[2]};
//     font-size: ${({theme}) => theme.fonts.size.md};
//     line-height: ${({theme}) => theme.fonts.lineHeight[1]};
//     text-align: start;
//     position: sticky;
//     left: 0;
//   }
//   &:last-child{
//     border-right: none;
//     text-align: end;
//     padding-right: 16px;
//   }
// `;

// export const StyledTh = styled(Th)`
//   padding: 16px 0;
//   border: 1px solid rgba(51, 51, 51, 0.2);
//   font-weight: ${({theme}) => theme.fonts.weight[2]};
//   font-size: ${({theme}) => theme.fonts.size.md};
//   line-height: ${({theme}) => theme.fonts.lineHeight[2]};

//   &:first-child{
//     border-left: none;
//     text-align: start;
//     position: sticky;
//     left: 0;
//     background-color: #fff;
//   }
//   &:last-child{
//     border-right: none;
//   }
// `;
