import React, { useState } from "react";
import PropTypes from "prop-types";
import { Themes, ThemeContext } from "themes";
import { ThemeProvider as SThemeProvider } from "styled-components";
import { THEME } from "environment";

const ThemeProvider = ({ children }) => {
  const [themeType, setThemeType] = useState("light");
  const theme = Themes[String(THEME) || "default"][themeType];

  const toggleTheme = () => {
    setThemeType(themeType === "light" ? "dark" : "light");
  };

  return (
    <SThemeProvider theme={theme}>
      <ThemeContext.Provider
        value={{ toggleTheme, theme, themeType, setThemeType }}
      >
        {children}
      </ThemeContext.Provider>
    </SThemeProvider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export default ThemeProvider;
