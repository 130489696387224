import styled from "styled-components";

export const Container = styled.div`
  display: none;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  width: 152px;
  padding: 10px 20px;
  box-sizing: border-box;
  border-radius: 2px;

  margin: 0 auto 35px auto;

  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fonts.size.lg};
  font-weight: ${({ theme }) => theme.fonts.weight[0]}};
  line-height: ${({ theme }) => theme.fonts.lineHeight[2]};

  background-color: ${({ theme }) =>
    theme.colors[theme.background.tertiary.color][
      theme.background.tertiary.intensity
    ]};
  color: ${({ theme }) =>
    theme.colors[theme.textColors.secondary.color][
      theme.textColors.secondary.intensity
    ]};


  cursor: pointer;

  /* @media (max-width: 920px) {
    display: flex;
    font-size: ${({ theme }) => theme.fonts.size.md};
    background-color: ${({ theme }) =>
      theme.colors[theme.background.secondary.color][
        theme.background.secondary.intensity
      ]};
  } */
`;
