import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Wrapper = styled.span`
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) =>
    theme.colors[theme.textColors.primary.color][
      theme.textColors.primary.intensity
    ]};
  font-size: ${({ theme, textsize }) => theme.fonts.size[textsize || "md"]};
  font-weight: ${({ weight }) => (weight ? weight : "normal")};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: ${({ theme, lineHeight }) =>
    lineHeight ? lineHeight : theme.fonts.lineHeight[2]};

  /* @media (max-width: 920px) {
    color: ${({ theme }) =>
    theme.colors[theme.textColors.secondary.color][
      theme.textColors.secondary.intensity
    ]};
    text-overflow: initial;
    word-wrap: normal;
    white-space: pre-line;
  } */
`;

const Text = ({ id, children, className, textsize, bold, weight, style }) => {
  return (
    <Wrapper
      id={id}
      className={className}
      textsize={textsize}
      bold={bold}
      weight={weight}
      style={style}
    >
      {children}
    </Wrapper>
  );
};

Text.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
  textsize: PropTypes.oneOf(["sm", "md", "lg"]),
  bold: PropTypes.bool,
  color: PropTypes.oneOf([
    "white",
    "black",
    "gray",
    "blue",
    "pink",
    "green",
    "yellow",
    "orange",
    "red",
    "purple",
  ]),
};

export default Text;
