import React from "react";
import { useSelectState } from "../../../state";
import { REDUX_PANE } from "environment";

export const ReduxStatePane = () => {
  const stateForLog = useSelectState();
  return (
    REDUX_PANE && (
      <div
        style={{
          backgroundColor: "black",
          color: "lightgreen",
          padding: "2rem",
        }}
      >
        <pre>Redux log:</pre>

        <pre>{JSON.stringify(stateForLog, 2, 3)}</pre>
      </div>
    )
  );
};
