import styled from "styled-components";

import { Text } from "components";

export const StyledText = styled(Text)`
  font-weight: normal;
  line-height: 20px;
  color: rgba(102, 102, 102, 0.8);

  .highlighted-word {
    font-weight: bolder;
    color: inherit;
    background-color: inherit;
  }

  /* @media (max-width: 920px) {
    line-height: 16px;
    color: rgba(51, 51, 51, 0.9);
  } */
`;
