import React from "react";

import { useMyContext } from "hooks";
import SearchAssetInfoItem from "../SearchAssetInfoItem";

const SearchStockInfo = ({ type, stockExchange, situation }) => {
  const { t } = useMyContext();

  return (
    <>
      <SearchAssetInfoItem
        label={t["organisms:SearchAssets:type"]}
        value={type}
      />
      <SearchAssetInfoItem
        label={t["organisms:SearchAssets:stockExchange"]}
        value={stockExchange}
      />
      <SearchAssetInfoItem
        label={t["organisms:SearchAssets:situation"]}
        value={situation}
      />
    </>
  );
};

export default SearchStockInfo;
