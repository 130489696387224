import React, { useState } from "react";
import Modal from "react-modal";
import { HexColorPicker } from "react-colorful";
import { useAssets, useMyContext } from "hooks";

import {
  Picker,
  ModalContainer,
  StyledButton,
  ButtonsContainer,
  StyledTitle,
} from "./styles";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "250px",
  },
};

const ColorPicker = ({ className, value, asset }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedActiveColor, setSelectedActiveColor] = useState(
    asset ? asset.color : ""
  );
  const { changeAssetColor } = useAssets();
  const { t } = useMyContext();

  const openModal = () => {
    setIsOpenModal(true);
  };

  const closeModal = () => {
    setIsOpenModal(false);
  };

  const changeColor = () => {
    changeAssetColor(selectedActiveColor, asset.identifier, asset.assetType);
    closeModal();
  };

  const resetActiveColor = () => {
    setSelectedActiveColor(asset.color);
    closeModal();
  };

  return (
    <>
      <Picker className={className} value={value} onClick={openModal} />
      <Modal
        isOpen={isOpenModal}
        onRequestClose={closeModal}
        contentLabel="Color Picker"
        style={customStyles}
      >
        <ModalContainer>
          <StyledTitle fontWeight={1}>Ativo: {asset.label}</StyledTitle>
          <HexColorPicker
            color={selectedActiveColor}
            onChange={setSelectedActiveColor}
          />
          <ButtonsContainer>
            <StyledButton
              bgColor="red"
              type="button"
              size="sm"
              onClick={resetActiveColor}
            >
              {t["molecules:ActiveBox:cancelar"]}
            </StyledButton>
            <StyledButton type="button" size="sm" onClick={changeColor}>
              {t["molecules:ActiveBox:selecionar"]}
            </StyledButton>
          </ButtonsContainer>
        </ModalContainer>
      </Modal>
    </>
  );
};

export default ColorPicker;
