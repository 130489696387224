import React, { useState } from "react";
import { FaRegTrashAlt } from "react-icons/fa";

import { MobileFooter, ColorPicker } from "components";
import { useMyContext, useAssets } from "hooks";
import {
  AssetRowWrapper,
  LeftContainer,
  RightContainer,
  AssetLabel,
  AssetTitle,
  RemoveButton,
  AssetName,
  Value,
} from "./styles";
import Utils from "utils/utils";

const AssetRow = ({ type, asset, title }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { themeType, t } = useMyContext();
  const {
    changeAssetColor,
    removeAsset,
    findBenchmarkAsset,
    selectedBenchmarksIndexes,
  } = useAssets();
  const { formatPercent } = Utils();

  const remove = (identifier, assetType) => {
    removeAsset(identifier, assetType);
    setIsOpenModal(false);
  };

  const isLastBenchmark = () => {
    const benchmarkIndex = findBenchmarkAsset(
      asset.identifier,
      asset.assetType
    );

    return benchmarkIndex !== -1 && selectedBenchmarksIndexes.length === 1;
  };

  const openModal = () => {
    if (!isLastBenchmark()) {
      setIsOpenModal(true);
    }
  };

  return (
    <AssetRowWrapper>
      <LeftContainer>
        <ColorPicker
          value={asset.color}
          changeAssetColor={changeAssetColor}
          asset={asset}
        />
      </LeftContainer>
      <RightContainer onClick={openModal}>
        <AssetName>{asset.label}</AssetName>
        <Value>
          {type === "indicatorsRentability" &&
            asset.indicatorsRentabilityData?.[title].value}
          {type === "indicatorsReturn" &&
            asset.indicatorsReturnData?.[title].value}
          {type === "indicatorsDrawdown" &&
            asset.indicatorsDrawdownData?.[title].value}
          {type === "history" &&
            formatPercent(asset.returnHistoryData?.[title])}
        </Value>
      </RightContainer>
      <MobileFooter
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        body={
          <>
            <AssetTitle themeType={themeType}>
              {t["molecules:Card:asset"]}:
            </AssetTitle>
            <AssetLabel themeType={themeType}>{asset.label}</AssetLabel>
          </>
        }
        bottom={
          <>
            <RemoveButton
              onClick={() => remove(asset.identifier, asset.assetType)}
            >
              <FaRegTrashAlt style={{ marginRight: "8px" }} />
              {t["common:remove"]}
            </RemoveButton>
          </>
        }
      />
    </AssetRowWrapper>
  );
};

export default AssetRow;
