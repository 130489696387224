import React, { useCallback, useEffect, useMemo } from "react";
import * as Highcharts from "highcharts";
import HighchartsExporting from "highcharts/modules/exporting";

import defaultTheme from "themes/Themes/clients/defaultTheme";
import { useMyContext } from "hooks";
import { usePeriodRange } from "../../../state";
import Utils from "utils/utils";
import { Wrapper } from "./styles";
import { useWindowSize } from "react-use";
import { contextButtonIcon } from "./../../../models/constants";

HighchartsExporting(Highcharts);

const HigchartsBase = ({ assets, selectedTab }) => {
  const { formatCurrency, formatPercent, formatDateFullYear } = Utils();
  const { themeType, t } = useMyContext();
  const [periodRange] = usePeriodRange();
  const theme = defaultTheme[themeType];

  const { width } = useWindowSize();

  const title = useMemo(() => {
    const { initialDate, finalDate } = periodRange;
    const formattedDate =
      initialDate && finalDate
        ? ` - ${formatDateFullYear(initialDate)} a ${formatDateFullYear(
            finalDate
          )}`
        : "";

    switch (selectedTab) {
      case 0:
        return `${t["chart:Rentability:title"]}${formattedDate}`;
      case 1:
        return `${t["chart:Return:title"]}${formattedDate}`;
      case 2:
        return `${t["chart:Underwater:title"]}${formattedDate}`;
      default:
        return null;
    }
  }, [periodRange, selectedTab]);

  const exportIconOffset = useMemo(() => {
    switch (selectedTab) {
      case 0:
        return { x: 75, y: 63 };
      case 1:
        return { x: 50, y: 63 };
      case 2:
        return { x: 45, y: 446 };
      default:
        return { x: 75, y: 60 };
    }
  }, [selectedTab]);

  const getData = useCallback(
    (asset, tab) => {
      switch (selectedTab) {
        case 0:
          return asset.rentabilitySeries;
        case 1:
          return asset.returnSeries;
        case 2:
          return asset.drawdownSeries;
        default:
          return null;
      }
    },
    [selectedTab]
  );

  const toolTipValue = useCallback(
    (value) => {
      switch (selectedTab) {
        case 0:
          return formatCurrency(value);
        case 1:
        case 2:
          return formatPercent(value);
        default:
          return value;
      }
    },
    [selectedTab]
  );

  const getType = useCallback(() => {
    switch (selectedTab) {
      case 0:
        return "line";
      case 1:
        return "line";
      case 2:
        return "area";
      default:
        return null;
    }
  }, [selectedTab]);

  function tooltipFormatter() {
    return this.points.reduce(
      function (s, point) {
        return `${s}<div style="display: flex; 
                justify-content: flex-start; 
                align-items: flex-start;
                padding-left: 8px;
                margin: 0;
                margin-bottom: 8px;
                color: rgba(2, 1, 1, 0.6);
                font-family: Montserrat;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 16px;
                max-width: 240px;
                ">
        <div style="background-color: ${point.series.color}; 
                    height: 16px;
                    width: 5px; 
                    min-width: 5px;
                    max-width: 5px;
                    margin-right: 4px">
                    &nbsp;
        </div>
        <div style="font-weight: 600; min-width: fit-content; padding-right: 6px;">
          ${toolTipValue(point.y)}
        </div>
        <div style="white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    ">
          ${point.series.name}
        </div>
    </div>`;
      },
      `    <div style="
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            margin: 8px;
            color: #333333;
          ">
        ${String(
          new Date(this.x).toLocaleString("pt-BR", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          })
        )}
        </div>`
    );
  }

  useEffect(() => {
    Highcharts.setOptions({
      lang: {
        months: [
          "Janeiro",
          "Fevevereiro",
          "Março",
          "Abril",
          "Maio",
          "Junho",
          "Julho",
          "Agosto",
          "Setembro",
          "Outubro",
          "Novembro",
          "Dezembro",
        ],
        shortMonths: [
          "01",
          "02",
          "03",
          "04",
          "05",
          "06",
          "07",
          "08",
          "09",
          "10",
          "11",
          "12",
        ],
        resetZoom: t["chart:resetZoom"],
        decimalPoint: ",",
        thousandsSep: ".",
        downloadPDF: t["chart:downloadPDF"],
        downloadJPEG: t["chart:downloadJPEG"],
        downloadPNG: t["chart:downloadPNG"],
      },
    });
    Highcharts.chart("line", {
      credits: {
        enabled: false,
      },
      chart: {
        style: {
          fontFamily: "Montserrat",
        },
        zoomType: "x",
        type: getType(),
        backgroundColor:
          width <= 0
            ? theme.colors[theme.background.quaternary.color][
                [theme.background.quaternary.intensity]
              ]
            : "white",
        events: {
          click: function (e) {
            //TODO: tela de detalhes
          },
        },
      },
      title: {
        text: width > 0 ? title : "",
        align: "left",
        x: 0,
        style: {
          fontFamily: "Montserrat",
          fontWeight: "bold",
          fontSize: "16px",
        },
      },
      subtitle: {
        text:
          document.ontouchstart === undefined
            ? t["chart:desktop:zoom"]
            : t["chart:mobile:zoom"],
      },
      xAxis: {
        type: "datetime",
        dateTimeLabelFormats: {
          month: "%b/%y",
          day: "%b/%y",
          week: "%b/%y",
        },
        gridLineWidth: 0,
        labels: {
          style: {
            color:
              width <= 0
                ? "white"
                : theme.colors[theme.textColors.primary.color][
                    theme.textColors.primary.intensity
                  ],
          },
        },
      },
      yAxis: {
        gridLineColor:
          width <= 0 ? "rgba(255, 255, 255, 0.2)" : "rgba(51, 51, 51, 0.2)",
        title: {
          text: false,
        },
        labels: {
          formatter: function () {
            switch (selectedTab) {
              case 0:
                return formatCurrency(this.value);
              case 1:
                return `${this.value}%`;
              case 2:
                return `${this.value}%`;
              default:
                return null;
            }
          },
          style: {
            color:
              width <= 0
                ? "white"
                : theme.colors[theme.textColors.primary.color][
                    theme.textColors.primary.intensity
                  ],
          },
        },
      },
      legend: {
        enabled: width > 0,
        itemStyle: {
          fontFamily: "Montserrat",
        },
        align: "center",
        layout: "horizontal",
        verticalAlign: "bottom",
        y: 10,
      },
      tooltip: {
        useHTML: true,
        shared: true,
        formatter: tooltipFormatter,
        borderRadius: 4,
        borderColor: "rgba(51, 51, 51, 0.5)",
        backgroundColor: "rgba(255, 255, 255, 0.9)",
        outside: true,
      },
      plotOptions: {
        line: {
          marker: {
            enabled: false,
          },
          animation: false,
        },
        area: {
          marker: {
            radius: 2,
          },
          animation: false,
          states: {
            hover: {
              lineWidth: 1,
            },
          },
          threshold: 0,
        },
      },
      series: assets.map((asset) => ({
        data: getData(asset, selectedTab),
        name: asset.label,
        lineWidth: 3,
        color: asset.color,
        fillOpacity: 0.2,
      })),
      exporting: {
        enabled: false,
        filename: "Download",
        buttons: {
          contextButton: {
            menuItems: ["downloadPNG", "downloadPDF", "downloadJPEG"],
            symbol: `url(data:image/svg+xml;base64,${contextButtonIcon})`,
            verticalAlign: "top",
            height: 24,
            width: 24,
            symbolX: 20,
            symbolY: 20,
            align: "left",
            ...exportIconOffset,
          },
        },
        sourceHeight: 864,
        sourceWidth: 1536,
        chartOptions: {
          legend: {
            enabled: true,
            adjustChartSize: true,
            padding: 20,
            itemDistance: 50,
          },
          subtitle: {
            text: "",
          },
          credits: {
            enabled: true,
            text: "Powered by Quantum",
            style: {
              fontSize: "10px",
            },
          },
        },
      },
    });
  }, [assets, selectedTab, title, width]);

  return (
    <Wrapper
      id="line"
      style={{
        height: "565px",
      }}
    />
  );
};

export default HigchartsBase;
