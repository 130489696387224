import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";

import { API_SUBSCRIPTION_KEY, THEME } from "../environment";

let appInsights = null;

const customTags = {
  "quantum.subscription_key": String(API_SUBSCRIPTION_KEY),
};

export const initialize = (connectionString, subscriptionKey) => {
  if (appInsights != null) {
    throw "Analytics já foi inicializado";
  }

  const browserHistory = createBrowserHistory({ basename: "" });
  const reactPlugin = new ReactPlugin();

  appInsights = new ApplicationInsights({
    config: {
      connectionString: connectionString,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory },
      },
    },
  });
  appInsights.loadAppInsights();
  appInsights.trackPageView({
    name: `Comparador de Ativos - ${THEME}`,
    properties: {
      ...customTags,
    },
  });

  appInsights.addTelemetryInitializer((envelope) => {
    envelope.tags["quantum.subscription_key"] = subscriptionKey;
  });
};

export const trackEvent = (event) => {
  if (appInsights == null) {
    console.warn("Analytics não inicializado.");
    return;
  }

  appInsights.trackEvent({
    ...event,
    properties: {
      ...event.properties,
      ...customTags,
    },
  });
};

export const trackPageview = (pageview) => {
  if (appInsights == null) {
    console.warn("Analytics não inicializado.");
    return;
  }

  appInsights.trackPageView({
    ...pageview,
    properties: {
      ...pageview.properties,
      ...customTags,
    },
  });
};
