import { trackEvent, trackPageview } from "./index";

const createAssetIdentifier = (asset) => {
  return `${asset.assetType}|${asset.identifier}`;
};

const managetSetPeriod = (store, action) => {
  console.log(`Periodo selecionado ${action.payload}`);
  trackEvent({
    name: "trocou_periodo",
    properties: {
      periodo_novo: action.payload,
      periodo_anterior: store.getState().selectedPeriod,
    },
  });
};

const manageSetInitialApplication = (store, action) => {
  console.log(`Aplicação inicial alterada: ${action.payload}`);
  trackEvent({
    name: "trocou_aplicacao_inicial",
    properties: {
      aplicacao_inicial: action.payload,
      aplicacao_anterior: store.getState().initialApplication,
    },
  });
};

const manageSetTabEvent = (store, action) => {
  const state = store.getState();
  trackPageview({
    name: `aba_${action.payload}`,
    properties: {
      aba_anterior: state.selectedTab,
      ativos: state.assets,
      periodo: state.selectedPeriod,
      benchmarks: state.benchmarks,
    },
  });
};

const manageAddAssetEvent = (store, action) => {
  const previousAssets = store.getState().assets;
  const currentAssets = action.payload;

  // ativos adicionados
  currentAssets
    .filter((asset) => !previousAssets.includes(asset))
    .forEach((asset) => {
      const idAtivo = createAssetIdentifier(asset);
      console.log(`adicionou ativo: ${idAtivo}`);
      trackEvent({
        name: "ativo_adicionado",
        properties: {
          ativo_id: idAtivo,
          ativo_nome: asset.label,
          ativos: currentAssets,
        },
      });
    });

  // ativos removidos
  previousAssets
    .filter((asset) => !currentAssets.includes(asset))
    .forEach((asset) => {
      const idAtivo = createAssetIdentifier(asset);
      console.log(`removeu ativo: ${idAtivo}`);
      trackEvent({
        name: "ativo_removido",
        properties: {
          ativo_id: idAtivo,
          ativo_nome: asset.label,
          ativos: currentAssets,
        },
      });
    });
};

export const createAnalyticsMiddleware =
  () => (store) => (next) => (action) => {
    switch (action.type) {
      case "ADD_ASSETS":
        manageAddAssetEvent(store, action);
        break;
      case "SET_TAB":
        manageSetTabEvent(store, action);
        break;
      case "SET_INITIAL_APPLICATION":
        manageSetInitialApplication(store, action);
        break;
      case "SET_PERIOD":
        managetSetPeriod(store, action);
        break;
    }
    return next(action);
  };
