import React, { useState } from "react";
import { render } from "react-dom";
import App from "./components/App";
import Providers from "./Providers";
import Modal from "react-modal";
import { LoadingMask } from "components";
import jwtDecode from "jwt-decode";

Modal.setAppElement("#app");

const renderApp = () => {
  const token = localStorage.getItem("token") || null;

  let decodedToken = null;

  try {
    decodedToken = jwtDecode(token);
  } catch {
    decodedToken = null;
  }

  const isTokenValid =
    decodedToken && typeof decodedToken.exp === "number"
      ? decodedToken.exp > Math.floor(Date.now() / 1000)
      : false;

  if (!isTokenValid) {
    const handleMessage = (event) => {
      try {
        const message = JSON.parse(String(event.data));

        if (Object.keys(message).includes("token")) {
          const decodedToken = jwtDecode(message.token);
          const isTokenValid =
            decodedToken && typeof decodedToken.exp === "number"
              ? decodedToken.exp > Math.floor(Date.now() / 1000)
              : false;

          if (isTokenValid) {
            localStorage.setItem("token", message.token);
            document.location.reload();
          } else {
            localStorage.removeItem("token");
            document.location.reload();
          }
        }
      } catch {
        // do nothing. rest api config already reboots if token is not valid or not passed
      }
    };

    window.addEventListener("message", handleMessage);

    return <LoadingMask solidBg={true} />;
  } else {
    return (
      <Providers>
        <App />
      </Providers>
    );
  }
};

render(renderApp(), document.getElementById("app"));
