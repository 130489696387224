import styled from "styled-components";
import { Div, Text } from "components";
import { Button } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

export const Wrapper = styled(Div)`
  display: flex;
  flex-direction: column;
`;

export const ActivesContainer = styled(Div)`
  display: flex;
  flex-wrap: wrap;
`;

export const SButton = styled(Button)`
  && {
    margin: 0;
    margin-top: 14px;
    padding: 8px;

    height: 37px;
    min-width: fit-content;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    /* background-color: ${({ theme }) => theme.colors.bgPrimary}; */
    border: 1px solid ${({ theme }) => theme.colors.red[0]};
    box-sizing: border-box;
    border-radius: 4px;
    color: ${({ theme }) => theme.colors.red[0]};
    text-transform: none;
    font-weight: 600;
    font-size: 14px;
    font-family: ${({ theme }) => theme.fonts.primary};

    &:hover {
      background-color: ${({ theme }) => theme.colors.red[0]};
      color: ${({ theme }) => theme.colors.white[0]};
    }
  }
`;

export const DeleteIcon = styled(DeleteOutlineIcon)`
  && {
    font-size: 14px;
    margin-right: 4px;
  }
`;

export const StyledText = styled(Text)`
  font-weight: 700;
  /* @media (max-width: 920px) {
    color: white;
    font-weight: 600;
    padding-bottom: 13px;
  } */
`;

export const TitleContainer = styled(Div)`
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  padding-bottom: 12px;
`;

export const SelectedAssetsContainer = styled(Div)`
  background-color: ${({ theme }) =>
    theme.colors[theme.background.sextenary.color][
      [theme.background.sextenary.intensity]
    ]};
  min-height: 360px;
  padding: 16px;

  /* @media (max-width: 920px) {
    margin-top 16px;
  } */
`;

export const SelectedAssetsList = styled.ul`
  list-style-type: none;
`;
