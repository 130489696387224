import React from "react";
import Highlighter from "react-highlight-words";

import { StyledText } from "./styles";

const SearchAssetInfoItem = ({ label, value, wordsToHighlight, sanitize }) => {
  return (
    <StyledText textsize={"md"}>
      {`${label}: `}

      {wordsToHighlight ? (
        <Highlighter
          searchWords={wordsToHighlight}
          textToHighlight={value}
          highlightClassName={"highlighted-word"}
          sanitize={sanitize}
        />
      ) : (
        <>{value}</>
      )}
    </StyledText>
  );
};

export default SearchAssetInfoItem;
