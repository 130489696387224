import styled from "styled-components";
import CurrencyInput from "react-currency-input";
import { Div, NavItem as Item, Text } from "components";
import { AppBar, Toolbar, Typography } from "@mui/material";

export const HeaderWrapper = styled(Div)`
  width: 100%;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  padding: 24px 0;
`;

export const NavBox = styled(Div)`
  display: flex;
  flex-direction: column;
`;

export const NavItem = styled(Item)`
  border: ${({ hasBorder, theme }) =>
    !!hasBorder &&
    `1px solid ${
      theme.colors[theme.background.tertiary.color][
        theme.background.tertiary.intensity
      ]
    }`};
  padding: ${({ hasBorder }) => !!hasBorder && "10px 14px"};
  border-radius: ${({ hasBorder }) => !!hasBorder && "4px"};
  font-size: 14px;
  & + & {
    margin-left: ${({ hasBorder }) => (!!hasBorder ? "12px" : "0px")};
  }
`;

export const NavLeft = styled.ul`
  display: flex;
  list-style-type: none;
  border: 1px solid ${({ theme }) => theme.colors.gray[theme.borders[0]]};
`;

export const NavMiddle = styled.ul`
  display: flex;
  list-style-type: none;
  border: 1px solid ${({ theme }) => theme.colors.gray[theme.borders[0]]};
`;

export const NavRight = styled.ul`
  display: flex;
  list-style-type: none;
  border: 1px solid ${({ theme }) => theme.colors.gray[theme.borders[0]]};
`;

export const StyledText = styled(Text)`
  color: ${({ theme }) =>
    theme.colors[theme.textColors.primary.color][
      theme.textColors.primary.intensity
    ]};
  margin-bottom: 8px;
`;

export const StyledFooterText = styled(Text)`
  color: ${({ theme }) =>
    theme.colors[theme.textColors.secondary.color][
      theme.textColors.secondary.intensity
    ]};
`;

export const StyledCurrencyInput = styled(CurrencyInput)`
  padding: 12px 12px 12px 0px;
  text-align: end;
  border: none;
  background-color: ${({ theme }) =>
    theme.colors[theme.background.quaternary.color][
      theme.background.quaternary.intensity
    ]};
`;

export const SAppBar = styled(AppBar)`
  && {
    border-radius: 4px;
    height: 44px;

    div {
      padding-left: 16px;
    }
  }
`;

export const SToolbar = styled(Toolbar)`
  && {
    height: 44px;
    min-height: 44px;
    max-height: 44px;
  }
`;

export const STypography = styled(Typography)`
  && {
    font-size: 16px;
    font-weight: bold;
    font-family: ${({ theme }) => theme.fonts.primary};
    color: ${({ theme }) => theme.textColors.secondary.color};
  }
`;
