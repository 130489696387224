import React from "react";
import { Wrapper } from "./styles";
import PropTypes from "prop-types";

const Header = ({ children, bgColor, showBorder, className, style }) => {
  return (
    <Wrapper
      bgColor={bgColor}
      showBorder={showBorder}
      className={className}
      style={style}
    >
      {children}
    </Wrapper>
  );
};

Header.propTypes = {
  children: PropTypes.node,
  bgColor: PropTypes.string,
  showBorder: PropTypes.bool,
};

export default Header;
