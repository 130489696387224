import styled from "styled-components";
import { Div } from "components";

export const ModalContainer = styled(Div)`
  display: flex;
  flex-direction: column;
`;

export const TopBar = styled(Div)`
  display: flex;
  justify-content: flex-end;
  padding: 12px 16px;
  border-bottom: 1px solid rgba(33, 33, 33, 0.2);
`;

export const Body = styled(Div)`
  display: flex;
  flex-direction: column;
  padding: 16px;
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme, themeType }) =>
    themeType === "dark"
      ? theme.colors[theme.textColors.secondary.color][
          theme.textColors.secondary.intensity
        ]
      : theme.colors[theme.textColors.primary.color][
          theme.textColors.primary.intensity
        ]};
`;

export const BottomBar = styled(Div)`
  display: flex;
  padding: 16px 0;
  justify-content: center;
  border-top: 1px solid rgba(33, 33, 33, 0.2);
`;

export const CloseButton = styled.button`
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: ${({ theme, themeType }) =>
    themeType === "dark"
      ? theme.colors[theme.textColors.secondary.color][
          theme.textColors.secondary.intensity
        ]
      : theme.colors[theme.textColors.primary.color][
          theme.textColors.primary.intensity
        ]};
`;
