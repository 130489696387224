import { HOME_TITLE } from "environment";

const enUS = {
  "period:to": "to",
  "period:calc": "calculating ...",
  "period:baseDate": "Base date",

  "common:remove": "Delete",
  "common:return": "Return",
  "common:asset": "Asset",

  "chart:resetZoom": "Cancelar zoom",
  "chart:desktop:zoom":
    "Clique e arraste na área do gráfico para aumentar o zoom",
  "chart:mobile:zoom": "Utilize 2 dedos para dar zoom",
  "chart:downloadPDF": "Download PDF",
  "chart:downloadPNG": "Download PNG",
  "chart:downloadJPEG": "Download JPEG",

  "molecules:TitleHome:Title:title-1":
    HOME_TITLE || "Comparador de ativos OUI Investimentos,",
  "molecules:TitleHome:Title:title-2": "mais que fundos de investimentos",
  "molecules:ConfigurationHeader:benchmark": "Benchmarks",
  "molecules:ConfigurationHeader:periodo": "Período",
  "molecules:ConfigurationHeader:aplicaoInicial": "Aplicação Inicial",
  "molecules:ActiveBox:cancelar": "Cancelar",
  "molecules:ActiveBox:selecionar": "Selecionar",
  "molecules:Modals:SerieModal:alert":
    "Alguns ativos não serão exibidos no gráfico pois não possuem série histórica em todo esse período.",
  "molecules:Modals:MobileFooter:close": "Fechar",
  "molecules:Modals:MobileFooter:shareLinkMessage": "Sharable Link:",
  "molecules:Modals:MobileFooter:shareQRTitle": "QR Code",
  "molecules:Modals:MobileFooter:shareQRCodeMessage":
    "Open your Camera app and point your phone at the QR code.",
  "molecules:Modals:MobileFooter:copy": "Copy",
  "molecules:Modals:MobileFooter:copyed": "Copyed!",
  "molecules:Card:asset": "Ativo",

  "organisms:SearchAssets:searchString":
    "Digite para buscar ativos (nome, CNPJ ou ticker)",
  "organisms:SelectedActives:title": "Ativos selecionados",
  "organisms:SearchAssets:cnpj": "CNPJ",
  "organisms:SearchAssets:isin": "ISIN",
  "organisms:SearchAssets:managementCompany": "Management Company",
  "organisms:SearchAssets:type": "Type",
  "organisms:SearchAssets:situation": "Situation",
  "organisms:SearchAssets:stockExchange": "Stock Exchange",
  "organisms:SearchAssets:nenhumResultadoEncontrado":
    "Nenhum resultado encontrado",
  "organisms:SearchHeader:viewSelectedAssets": "View selected assets",
  "organisms:OverlayRow:remover": "Delete",

  "pages:HomePage:ClearActives": "Limpar todos",
  "pages:HomePage:AnalysisButton": "Iniciar análise dos ativos selecionados ",
  "pages:HomePage:AnalysisButton:mobile": "Iniciar análise dos ativos",
  "table:Header:Indicators": "Indicadores no período selecionado",
  "table:Header:ReturnHistory": "Histórico de Retorno",

  "tabs:Rentability": "Rentabilidade",
  "tabs:Return": "Retorno",
  "tabs:Underwater": "Underwater",
  "tabs:Correlation": "Correlation",

  "share:shareLink": "Share Link",
  "share:QRCode": "Generate QR Code",
  "share:copyLink": "Copy Link",
  "share:copiedLink": "Copied Link!",
  "share:invalidLink": "Invalid Link!",
  "share:loadingLink": "Link is loading... please wait.",

  "month:0": "Jan",
  "month:1": "Fev",
  "month:2": "Mar",
  "month:3": "Abr",
  "month:4": "Mai",
  "month:5": "Jun",
  "month:6": "Jul",
  "month:7": "Ago",
  "month:8": "Set",
  "month:9": "Out",
  "month:10": "Nov",
  "month:11": "Dec",

  "shortMonth:0": "Jan",
  "shortMonth:1": "Feb",
  "shortMonth:2": "Mar",
  "shortMonth:3": "Apr",
  "shortMonth:4": "May",
  "shortMonth:5": "Jun",
  "shortMonth:6": "Jul",
  "shortMonth:7": "Aug",
  "shortMonth:8": "Sep",
  "shortMonth:9": "Oct",
  "shortMonth:10": "Nov",
  "shortMonth:11": "Dec",

  "period:NO_MES": "Month",
  "period:UM_ANO_UTIL": "1 year",
  "period:SEIS_MESES_UTEIS": "6 months",
  "period:CINCO_ANOS_UTEIS": "5 years",
  "period:OTIMO": "Optimal",

  "measure:RENTABILIDADE": "Rentabilidade",
  "measure:PATRIMONIO_LIQUIDO": "PL. Milhões",
  "measure:SHARPE": "Sharpe",
  "measure:TAXA_DE_ADMINISTRACAO": "Taxa de admin",
  "measure:VOLATILIDADE": "Volatilidade",
  "measure:RETORNO": "Retorno",
  "measure:RETORNO_UM_ANO": "Retorno 1 ano",
  "measure:RETORNO_YTD": "Retorno YTD",
  "measure:DRAW_DOWN": "Drawdown",
  "measure:INICIO_DO_1_DRAW_DOWN": "Início do Máximo Drawdown",
  "measure:DATA_DO_1_DRAW_DOWN": "Data do Máximo Drawdown",
  "measure:PRIMEIRO_DRAW_DOWN": "Máximo Drawdown",
  "measure:DRAW_DOWN:abv": "Drawdown",
  "measure:INICIO_DO_1_DRAW_DOWN:abv": "Iní. máx. D.D.",
  "measure:DATA_DO_1_DRAW_DOWN:abv": "Data máx. D.D.",
  "measure:PRIMEIRO_DRAW_DOWN:abv": "Máx. D.D.",

  "assetType:FI": "FI",
  "assetType:FII": "FII",
  "assetType:EUROPA": "Europa",
  "assetType:ACAO": "Ação",
  "assetType:RENDA_FIXA": "Renda Fixa",
  "assetType:EMPRESA_ABERTA": "Empresa Aberta",

  "assetCategory:FUNDO": "Fundos",
  "assetCategory:ACAO": "Ações",
  "assetCategory:RENDA_FIXA": "Renda Fixa",
  "assetCategory:PREVIDENCIA": "Previdência",
  "assetCategory:EUROPA": "Europa",

  "chart:Rentability:title": "Profitability Chart",
  "chart:Return:title": "Return Chart",
  "chart:Underwater:title": "Underwater Chart",
  "chart:Correlation:title": "Correlation Chart",
};

export default enUS;
