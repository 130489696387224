import React, { useState, useEffect } from "react";
import { createQuantumApi } from "../../../api";
import { useMyContext, useAssets } from "hooks";
import {
  Wrapper,
  NavBox,
  NavRight,
  NavMiddle,
  NavLeft,
  NavItem,
  StyledText,
  StyledCurrencyInput,
} from "./styles";
import { Period, InitialApplication } from "components";
import {
  useSelectInicialApplication,
  useUserHasInteractedWithPeriod,
} from "../../../state";
import { useWindowSize, useDebounce } from "react-use";

const api = createQuantumApi();

const ConfigurationHeader = ({
  setSelectedPeriod,
  selectedPeriod,
  selectedTab,
}) => {
  const {
    benchmarkAssets,
    selectedBenchmarksIndexes,
    setSelectedBenchmarksIndexes,
    addAsset,
    removeAsset,
  } = useAssets();
  const [periods, setPeriods] = useState([]);

  const [initialApplication, setInitialApplication] =
    useSelectInicialApplication();
  const [, setUserHasInteractedWithPeriod] = useUserHasInteractedWithPeriod();

  const [value, setValue] = useState(initialApplication);

  const MIN_APPLICATION = 1;
  const MAX_APPLICATION = 1000000000;
  const { t } = useMyContext();
  const { width } = useWindowSize();

  const fetchPeriods = async () => {
    const periods = await api.fetchPeriods();
    setPeriods(periods);
  };

  const handleBenchmarkClick = async (e) => {
    const index = Number(e.currentTarget.dataset.index);

    if (
      selectedBenchmarksIndexes.includes(index) &&
      selectedBenchmarksIndexes.length > 1
    ) {
      setSelectedBenchmarksIndexes(
        selectedBenchmarksIndexes.filter((x) => x !== index)
      );
    }

    if (
      selectedBenchmarksIndexes.length === 1 &&
      selectedBenchmarksIndexes.includes(index)
    )
      return;

    if (selectedBenchmarksIndexes.includes(index)) {
      await removeAsset(
        benchmarkAssets[index].identifier,
        benchmarkAssets[index].assetType
      );
    } else {
      await addAsset(
        benchmarkAssets[index],
        selectedPeriod,
        initialApplication
      );
    }
  };

  const handlePeriodClick = (e) => {
    const index = e.currentTarget.dataset.index;
    setSelectedPeriod(periods[index]);
    setUserHasInteractedWithPeriod(true);
  };

  const handleFocus = (event) => {
    setTimeout(() => {
      const valueSize = String(event.target.value).length;
      event.target.select();
      event.target.selectionEnd = valueSize;
      event.target.selectionStart = 3;
    }, 0);
  };

  const handleBlur = (event) => {
    if (initialApplication !== value) {
      if (value < MIN_APPLICATION) {
        setValue(MIN_APPLICATION);
        setInitialApplication(MIN_APPLICATION);
      } else if (value > MAX_APPLICATION) {
        setValue(MAX_APPLICATION);
        setInitialApplication(MAX_APPLICATION);
      } else {
        setInitialApplication(value);
      }
    }
  };

  const handleChange = (event, _, floatvalue) => {
    event.preventDefault();
    if (floatvalue > MAX_APPLICATION) {
      setValue(MAX_APPLICATION);
    } else {
      setValue(floatvalue);
    }
  };

  const handleKeyPress = (event) => {
    if (event.code === "Enter") {
      handleBlur();
      event.target.blur();
    }
  };

  const benchMarkIsSelected = (index) => {
    return (
      selectedBenchmarksIndexes.length !== 0 &&
      selectedBenchmarksIndexes.includes(index)
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchPeriods();
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (value !== initialApplication) {
      setValue(initialApplication);
    }
  }, [initialApplication]);

  useDebounce(
    () => {
      handleBlur();
    },
    1000,
    [value]
  );

  return (
    <Wrapper>
      {width > 0 ? (
        <>
          <NavBox style={{ width: "250px" }}>
            {selectedTab === 0 && (
              <>
                <StyledText bold>
                  {t["molecules:ConfigurationHeader:aplicaoInicial"]}
                </StyledText>
                <NavMiddle style={{ border: "none" }}>
                  <StyledCurrencyInput
                    value={value}
                    decimalSeparator=","
                    thousandSeparator="."
                    prefix="R$ "
                    allowNegative={false}
                    onKeyPress={handleKeyPress}
                    onBlur={handleBlur}
                    maxLength={17}
                    onChangeEvent={handleChange}
                    onFocus={handleFocus}
                  />
                </NavMiddle>
              </>
            )}
          </NavBox>

          <NavBox>
            <StyledText bold>
              {t["molecules:ConfigurationHeader:benchmark"]}
            </StyledText>
            <NavLeft style={{ border: "none" }}>
              {benchmarkAssets &&
                benchmarkAssets.map((benchMark, index) => (
                  <NavItem
                    key={index}
                    hasBorder
                    index={index}
                    isSelected={benchMarkIsSelected(index)}
                    onClick={handleBenchmarkClick}
                  >
                    {benchMark.label}
                  </NavItem>
                ))}
            </NavLeft>
          </NavBox>

          <NavBox>
            <StyledText bold>
              {t["molecules:ConfigurationHeader:periodo"]}
            </StyledText>
            <NavRight style={{ border: "none" }}>
              {periods &&
                periods.map((period, index) => (
                  <NavItem
                    key={index}
                    hasBorder
                    index={index}
                    isSelected={selectedPeriod === period}
                    onClick={handlePeriodClick}
                  >
                    {t[`period:${period}`]}
                  </NavItem>
                ))}
            </NavRight>
          </NavBox>
        </>
      ) : (
        <>
          <NavBox>
            <StyledText bold>
              {t["molecules:ConfigurationHeader:benchmark"]}
            </StyledText>
            <NavLeft style={{ border: "none" }}>
              {benchmarkAssets &&
                benchmarkAssets.map((benchMark, index) => (
                  <NavItem
                    key={index}
                    hasBorder
                    index={index}
                    isSelected={benchMarkIsSelected(index)}
                    onClick={handleBenchmarkClick}
                  >
                    {benchMark.label}
                  </NavItem>
                ))}
            </NavLeft>
          </NavBox>

          <Period
            selectedPeriod={selectedPeriod}
            periods={periods}
            setSelectedPeriod={setSelectedPeriod}
            selectedTab={selectedTab}
          />

          {selectedTab === 0 && (
            <InitialApplication
              initialApplication={initialApplication}
              setInitialApplication={setInitialApplication}
            />
          )}
        </>
      )}
    </Wrapper>
  );
};

export default ConfigurationHeader;
