import React, { useEffect, useRef, useState } from "react";

import {
  MainTemplate,
  Img,
  TitleHome,
  SearchAssets,
  SelectedAssets,
} from "components";
import {
  AnalysisButton,
  BackgroundImages,
  BottomContainer,
  Content,
  Image1,
  Image2,
  Image3,
  LogoWrapper,
} from "./styles";
import { useMyContext, useAssets } from "hooks";
import { useSelectAssets } from "../../../state";
import { ReduxStatePane } from "../../organisms/ReduxStatePane";
import { useWindowSize } from "react-use";

const Logo = ({ src }) => {
  return (
    <LogoWrapper>
      <Img src={src} />
    </LogoWrapper>
  );
};

const HomePage = () => {
  const { t, theme } = useMyContext();
  const serachStringRef = useRef();

  // const [selectedAssets, setSelectedAssets] = useState([]);
  const [selectedAssets, setSelectedAssets] = useSelectAssets();
  const [assetsWithoutBenchmarks, setAssetsWithoutBenchmarks] = useState([]);
  const { getAssetColor, removeAllAssetsExceptBenchmarks } = useAssets();
  const { width } = useWindowSize();

  useEffect(() => {
    const assetsWithoutBenchmarks = selectedAssets.filter(
      ({ assetType }) => assetType !== "INDICE"
    );
    setAssetsWithoutBenchmarks(assetsWithoutBenchmarks);
  }, [selectedAssets]);

  const hasAsset = (assetId, assetType) => {
    return (
      selectedAssets.findIndex(
        (asset) => asset.identifier === assetId && asset.assetType === assetType
      ) !== -1
    );
  };

  const handleAddAsset = (asset) => {
    if (!hasAsset(asset.identifier, asset.assetType)) {
      const color = getAssetColor(selectedAssets, asset.assetType);
      setSelectedAssets([...selectedAssets, { ...asset, color }]);
    }
  };

  const handleRemoveAsset = (assetId, assetType) => {
    const assetToRemoveIndex = selectedAssets.findIndex(
      (asset) => asset.identifier === assetId && asset.assetType === assetType
    );
    const newArray = [...selectedAssets];
    newArray.splice(assetToRemoveIndex, 1);
    setSelectedAssets([...newArray]);
  };

  return (
    <MainTemplate
      id="homepage"
      header={theme.logo.show && <Logo src={theme.logo.img} />}
    >
      <Content>
        <TitleHome />

        <ReduxStatePane />
        <SearchAssets
          searchStringRef={serachStringRef}
          style={
            width > 0 ? { marginTop: "32px", width: "100%" } : { width: "100%" }
          }
          addAsset={handleAddAsset}
        />
        <SelectedAssets
          assets={assetsWithoutBenchmarks}
          removeAsset={handleRemoveAsset}
          clearAssets={removeAllAssetsExceptBenchmarks}
        />

        {selectedAssets.length > 0 && (
          <BottomContainer>
            <AnalysisButton
              to={{
                pathname: "/graphic",
                state: { selectedAssets },
              }}
            >
              {width > 0
                ? t["pages:HomePage:AnalysisButton"]
                : t["pages:HomePage:AnalysisButton:mobile"]}
            </AnalysisButton>
          </BottomContainer>
        )}
      </Content>
      {width > 0 && theme.backgroundImages.length > 0 && (
        <BackgroundImages>
          <Image1 src={theme.backgroundImages[0]} />
          <Image2 src={theme.backgroundImages[1]} />
          <Image3 src={theme.backgroundImages[2]} />
        </BackgroundImages>
      )}
    </MainTemplate>
  );
};

export default HomePage;
