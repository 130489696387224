import styled from "styled-components";
import { Swiper } from "swiper/react";

export const SwiperContainer = styled(Swiper)`
  height: 100%;
  margin-bottom: 39px;
  margin-top: 16px;

  & + & {
    margin-top: 0px;
  }

  .swiper-pagination {
    bottom: 0px !important;
  }

  .swiper-pagination-bullet-active {
    /* @media (max-width: 920px) {
      background-color: white;
    } */
  }
  .swiper-pagination-bullet {
    /* @media (max-width: 920px) {
      background-color: rgba(255, 255, 255, 0.4);
    } */
  }
`;
