import React from "react";

import { useMyContext } from "hooks";
import { Wrapper, Title, AssetList } from "./styles";
import AssetRow from "./AssetRow";

export const Card = ({ assetsData, title, type }) => {
  const { t } = useMyContext();

  if (assetsData.length === 0) {
    return <></>;
  }

  return (
    <Wrapper>
      <Title>
        {type === "indicatorsRentability" && (
          <>
            <strong>{title}</strong>
            <span>
              {assetsData[0].indicatorsRentabilityData[title] &&
                assetsData[0].indicatorsRentabilityData[title].period}
            </span>
          </>
        )}
        {type === "indicatorsReturn" && (
          <>
            <strong>{title}</strong>
            <span>
              {assetsData[0].indicatorsReturnData[title] &&
                assetsData[0].indicatorsReturnData[title].period}
            </span>
          </>
        )}
        {type === "indicatorsDrawdown" && (
          <>
            <strong>
              {t[`${Object.keys(t).find((key) => t[key] === title)}:abv`]}
            </strong>
            <span>
              {assetsData[0].indicatorsDrawdownData[title] &&
                assetsData[0].indicatorsDrawdownData[title].period}
            </span>
          </>
        )}
        {type === "history" && (
          <>
            <strong>{t["common:return"]}</strong>
            <span>{title}</span>
          </>
        )}
      </Title>
      <AssetList>
        {assetsData.map((asset) => (
          <AssetRow
            key={asset.identifier + asset.assetType}
            asset={asset}
            title={title}
            type={type}
          />
        ))}
      </AssetList>
    </Wrapper>
  );
};

export default Card;
