import styled from "styled-components";
import { rgba } from "polished";
import { Div, Input, Icon } from "components";

export const Wrapper = styled(Div)`
  display: flex;
  align-items: center;
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "344px")};
  background-color: ${({ theme }) =>
    rgba(
      theme.colors[theme.input.placeholder.primary.color][
        theme.input.placeholder.primary.intensity
      ],
      0.08
    )};
  margin-top: ${(props) => (props.style ? props.style.marginTop : "none")};
  :focus {
    border: 1px solid ${({ theme }) => theme.colors.gray[theme.borders[0]]};
  }
`;

export const StyledInput = styled(Input)`
  margin-left: 12px;
  border: none;
  background-color: transparent;
  font-weight: ${({ theme }) => theme.input.weight[0]};
  font-size: 14px;

  :hover {
    border: none;
  }
  :focus {
    border: none;
    padding: 0;
    margin: 0 12px;
  }
`;

export const StyledLeftIcon = styled(Icon)`
  align-self: center;
  font-size: ${({ theme, iconsize }) => theme.sizes.icon[iconsize]};
  color: ${({ theme }) =>
    `${rgba(
      theme.colors[theme.input.textcolor.color][
        theme.input.textcolor.intensity
      ],
      0.8
    )}`};
  margin-left: 12px;
`;
